<template>
  <div class="custom-field-inputs">
    <div v-if="showTitle" class="title">{{ title }}</div>
    <!-- The following v-if handles transient inconsistency between pushCustomFieldValues and pullCustomFieldValues during event loop.  -->
    <template v-if="applicableCustomFields.length === customFieldValues.length">
      <form-group
        v-for="(customField, index) in applicableCustomFields"
        :key="customField.id"
        :label="customField.name"
        :description="customField.helpText"
        :validator="v$.customFieldValues"
        :listItemIndex="index"
        listItemProperty="value"
        :disabled="disabled"
      >
        <template #default="slotProps">
          <boolean-radio-group
            v-if="customField.type === 'bool'"
            v-bind="slotProps"
            v-model="customFieldValues[index].value"
            :showClearButton="!customField.required && customField.default.value === null"
            :trueText="customField.boolTrueLabel"
            :falseText="customField.boolFalseLabel"
          />
          <custom-field-item-select
            v-else-if="customField.type === 'choice'"
            v-bind="slotProps"
            v-model="customFieldValues[index].value"
            :customField="customField.id"
            :name="customField.name"
            :multiple="customField.listMultiple"
          />
          <b-form-textarea
            v-else-if="customField.stringMultiline"
            v-bind="slotProps"
            v-model.trim="customFieldValues[index].value"
            rows="3"
            max-rows="3"
          />
          <b-form-input
            v-else
            v-bind="slotProps"
            v-model.trim="customFieldValues[index].value"
            :type="customField.type === 'number' ? 'number' : 'text'"
            :number="customField.type === 'number'"
          />
        </template>
      </form-group>
    </template>
  </div>
</template>
<script>
import _ from 'lodash'
import BooleanRadioGroup from '@/components/BooleanRadioGroup.vue'
import ChildValidation from '@/mixins/ChildValidation'
import CustomFieldItemSelect from '@/components/CustomFieldItemSelect.vue'
import { forceArray, hasValue } from '@/utils/misc'
import { useVuelidate } from '@vuelidate/core'
import { helpers } from '@vuelidate/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'CustomFieldInputs',
  setup () {
    return {
      v$: useVuelidate({ $scope: false, $stopPropagation: true })
    }
  },
  mixins: [ChildValidation],
  components: {
    BooleanRadioGroup,
    CustomFieldItemSelect
  },
  props: {
    appliesTo: {
      type: String,
      required: true
    },
    modelValue: Array,
    // Optionally specify customFieldId to only show that custom field.
    customFieldId: Number,
    showTitle: {
      type: Boolean,
      default: true
    },
    // areDefaultValues is set when editing user's work custom field default values.
    // It includes all work and display conditions, and does not validate required.
    areDefaultValues: {
      type: Boolean,
      default: false
    },
    // showEndCondition will include 'end' work fields, and 'transfer_out' on punch fields.
    showEndCondition: Boolean,
    // If enableUserConditions is true, then we'll filter conditions for specified org user, department,
    // and/or user labels.
    // We don't enable user conditions for bulk edits, because it can get complicated,
    // i.e., we'd need to pass every single user's profile data separately.
    enableUserConditions: Boolean,
    orgUser: Number,
    orgUnit: Number,
    department: Number,
    userLabels: Array,
    userCustomFieldValues: Array,
    job: Number,
    workLabels: Array,
    disabled: Boolean
  },
  emits: ['update:modelValue'],
  data () {
    return {
      applicableCustomFields: [],
      customFieldValues: []
    }
  },
  computed: {
    ...mapGetters({
      customFieldById: 'customFields/itemsById'
    }),
    title () {
      return `${_.capitalize(this.appliesTo)} Values`
    },
    customFields () {
      return this.$store.getters[`customFields/${this.appliesTo}CustomFields`]
    },
    appliesToWorkFields () {
      return this.appliesTo !== 'user'
    },
    enableWorkConditions () {
      return this.appliesToWorkFields && !this.areDefaultValues
    }
  },
  watch: {
    modelValue: {
      handler () {
        this.pullCustomFieldValues()
      },
      immediate: true
    },
    customFieldValues: {
      handler () {
        this.pushCustomFieldValues()
      },
      deep: true
    },
    customFields () {
      this.pullCustomFieldValues()
    },
    department () {
      this.pullCustomFieldValues()
    },
    job () {
      this.pullCustomFieldValues()
    },
    workLabels () {
      this.pullCustomFieldValues()
    }
  },
  methods: {
    pullCustomFieldValues () {
      // Copy custom field values from form to component state.
      if (this.$store.state.customFields.loading || this.customFields.length < 1) return
      [this.applicableCustomFields, this.customFieldValues] = this.generateCustomFieldValues(_.cloneDeep(this.modelValue))
      // console.log('pullCustomFieldValues sets applicableCustomFields', this.applicableCustomFields)
      // console.log('pullCustomFieldValues sets customFieldValues', this.customFieldValues)
    },
    pushCustomFieldValues () {
      // Copy custom field values from component state to v-model.
      if (this.$store.state.customFields.loading || this.customFields.length < 1) return
      // Order by field id in order to match backend behavior and thus avoid false dirty detection.
      let [applicableCustomFields, customFieldValues] = this.generateCustomFieldValues(this.customFieldValues, ['id'])
      customFieldValues = customFieldValues.filter(item => hasValue(item.value))
      if (!_.isEqual(customFieldValues, this.modelValue)) {
        // console.log('pushCustomFieldValues emitted change to modelValue', customFieldValues)
        this.$emit('update:modelValue', _.cloneDeep(customFieldValues))
      }
      this.applicableCustomFields = applicableCustomFields
      // console.log('pushCustomFieldValues sets applicableCustomFields', this.applicableCustomFields)
    },
    generateCustomFieldValues (fieldValues, orderBy=['sortOrder', 'name']) {
      // TODO: Filter custom field items.
      let customFields = this.customFields
      let customFieldValues = fieldValues

      if (this.customFieldId) {
        customFields = customFields?.filter(customField => customField.id === this.customFieldId)
      }

      if (this.enableUserConditions) {
        customFields = this.filterForUserConditions(customFields)
        const customFieldsById = Object.fromEntries(customFields.map(f => ([f.id, f])))
        customFieldValues = customFieldValues?.filter(customFieldValue => customFieldValue.field in customFieldsById)
      }

      if (this.enableWorkConditions) {
        // Run work filter twice in order to handle out-of-order inter-field dependencies.
        for (const n of _.range(2)) { // eslint-disable-line no-unused-vars
          customFields = this.filterForWorkConditions(customFields, customFieldValues)
          const customFieldsById = Object.fromEntries(customFields.map(f => ([f.id, f])))
          customFieldValues = customFieldValues?.filter(customFieldValue => customFieldValue.field in customFieldsById)
        }
      }

      const valuesByField = Object.fromEntries((customFieldValues || []).map(value => [value.field, value]))

      return [
        customFields,
        // TODO: How do you edit to clear a value without populating default?
        _.orderBy(customFields, orderBy).map(field => valuesByField[field.id] || {
          field: field.id,
          value: field.default.value,
          type: field.type,
          index: !this.areDefaultValues && field.active && ['bool', 'choice'].includes(field.type),
          crossIndex: field.crossIndex
        })
      ]
    },
    filterForUserConditions (items) {
      return items.filter(item => {
        const conditions = new Set(item.conditions || [])
        if (
          conditions.size < 1 ||
          conditions.has('user:all') ||
          conditions.has(`user:worker:${this.orgUser}`) ||
          conditions.has(`user:orgunit:${this.orgUnit}`) ||
          conditions.has(`user:dept:${this.department}`) ||
          _.some(this.userLabels || [], label => conditions.has(`user:label:${label}`))
        ) return true
        for (const customFieldValue of (this.userCustomFieldValues || [])) {
          if (_.some(forceArray(customFieldValue.value), v => conditions.has(`user:custom:${customFieldValue.field}:${v}`))) {
            return true
          }
        }
        return false
      })
    },
    filterForWorkConditions (items, customFieldValues) {
      // TODO: Should we filter for device conditions?
      const allowedDisplayConditions = new Set([
        'start',
        'update',
        this.showEndCondition ? 'end' : null,
        this.appliesTo === 'punch' ? 'transfer_in' : null,
        this.appliesTo === 'punch' && this.showEndCondition ? 'transfer_out' : null
      ].filter(c => !!c))

      return items.filter(item => {
        // Filter display conditions, unless it's admin only.
        if (!item.adminOnly && !item.displayConditions.some(displayCondition => allowedDisplayConditions.has(displayCondition))) {
          return false
        }

        const conditions = new Set(item.conditions || [])
        if (
          conditions.size < 1 ||
          conditions.has('work:all') ||
          conditions.has(`work:job:${this.job}`) ||
          _.some(this.workLabels, label => conditions.has(`work:label:${label}`))
        ) return true
        for (const customFieldValue of Object.values(customFieldValues)) {
          if (_.some(forceArray(customFieldValue.value), v => conditions.has(`work:custom:${customFieldValue.field}:${v}`))) {
            return true
          }
        }
        return false
      })
    },
  },
  validations () {
    return {
      customFieldValues: {
        $each: helpers.forEach({
          // TODO: Fix messages.
          value: {
            required: helpers.withMessage(
              props => this.$t('validations.required', { attribute: props.$response.attribute }),
              (value, parentVm) => {
                const customField = this.customFieldById[parentVm.field]
                return {
                  // Vuelidate's required function doesn't check booleans, so we need to do it ourselves.
                  $valid: this.areDefaultValues || !customField.required || hasValue(value, true),
                  attribute: customField.name
                }
              }
            ),
            numeric: helpers.withMessage(
              props => this.$t('validations.numeric', { attribute: props.$response.attribute }),
              (value, parentVm) => {
                if (!value) return true
                const customField = this.customFieldById[parentVm.field]
                if (customField.type !== 'number') return true
                return {
                  $valid: !isNaN(value),
                  attribute: customField.name
                }
              }
            ),
            maxLength: helpers.withMessage(
              props => this.$t('validations.maxLength', { attribute: props.$response.attribute, max: props.$response.max }),
              (value, parentVm) => {
                if (!value) return true
                const customField = this.customFieldById[parentVm.field]
                if (customField.type !== 'string') return true
                const max = customField.stringMultiline ? 200 : 100
                return {
                  $valid: value.length <= max,
                  attribute: customField.name,
                  max
                }
              }
            )
          }
        })
      }
    }
  },
  created () {
    this.$store.dispatch('customFields/load')
  }
}
</script>
<style lang="scss" scoped>
.custom-field-inputs {
  width: 100%;
  .title {
    text-decoration: underline;
    margin-bottom: .25rem;
    text-align: center;
  }
}
</style>

<template>
  <div role="tablist" class="employee-form">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1 basic-information" role="tab">
        <b-btn block v-b-toggle.accordion1 :variant="v$.$validationGroups.basicInformationGroup.$invalid ? 'danger' : 'dark'">
          <span class="text">Basic Information</span>
          <font-awesome-icon :icon="allTabsExpanded ? 'angle-double-up' : 'angle-double-down'" size="lg" class="toggle-all-tabs" @click.stop="toggleAllTabs" />
        </b-btn>
      </b-card-header>
      <b-collapse id="accordion1" ref="accordion1" visible :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row>
              <b-col cols="12" sm="6" v-if="canEditAdministrators">
                <form-group :validator="v$.form.roles">
                  <template #label>
                    {{ fieldLabels.roles }}
                    <help-text-icon>
                      <p>A user has one or more roles:</p>
                      <p>A <code>Worker</code> can use the time clock to punch, and this person's hours are tracked on the web site.</p>
                      <p>An <code>Administrator</code> has sign-in access to the web site, based on the allowed <code>Permissions</code> specified in the section below.</p>
                      <p>An <code>Account Owner</code> is able to make changes to the subscription billing for this account.</p>
                    </help-text-icon>
                  </template>
                  <template #default="slotProps">
                    <user-role-select
                      v-bind="slotProps"
                      v-model="form.roles"
                      :multiple="true"
                    />
                  </template>
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <form-group>
                  <b-form-checkbox v-model="form.active" :disabled="isSelf || originalData.banned">
                    Active
                    <help-text-icon>
                      When a user is no longer working for your organization, you can either set the user as not <code>Active</code>,
                      or delete the user in the <code>Danger Zone</code> section below.
                    </help-text-icon>
                  </b-form-checkbox>
                  <div v-if="isSelf">
                    <font-awesome-icon icon="hand-point-right" size="lg" />
                    THIS IS YOU
                  </div>
                  <div v-if="originalData.banned" class="banned">
                    <font-awesome-icon icon="ban" size="lg" />
                    User is banned
                  </div>
                </form-group>
              </b-col>
              <!-- <b-col cols="6">
                <form-group label="Avatar" label-cols>
                  <avatar :url="form.avatar" size="4rem" />
                </form-group>
              </b-col> -->
            </b-row>
            <b-row v-if="isAdmin">
              <b-col cols="12" sm="6">
                <form-group
                  :label="fieldLabels.email"
                  :validator="v$.form.email"
                >
                  <template #default="slotProps">
                    <div>
                      <b-form-input
                        v-bind="slotProps"
                        v-model.trim="form.email"
                        type="email" />
                      <div v-if="v$.form.email.$pending" class="invalid-feedback d-block">
                        <font-awesome-icon icon="circle-notch" spin /> Checking if email is unique...
                      </div>
                    </div>
                  </template>
                </form-group>
              </b-col>
              <b-col cols="12" sm="6" v-if="!addingNew && isAdmin">
                <form-group label="Status">
                  <template v-if="originalData.accepted">
                    Registered
                  </template>
                  <template v-else-if="originalData.user">
                    Invitation email sent
                    <b-button class="resend-invite" variant="warning" @click="resendInvite" :disabled="resendingInvite || isAllEmailSuppressed">
                      <font-awesome-icon icon="retweet" />
                      Resend invite
                    </b-button>
                    <div v-if="isAllEmailSuppressed" class="suppression-warning">
                      <font-awesome-icon icon="triangle-exclamation" />
                      This user is not able to receive emails. See "Notifications" section below.
                    </div>
                  </template>
                </form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" sm="6">
                <form-group :validator="v$.form.firstName" :label="fieldLabels.firstName">
                  <template #default="slotProps">
                    <b-form-input
                      v-bind="slotProps"
                      v-model.trim="form.firstName"
                      type="text"
                    />
                  </template>
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <form-group :validator="v$.form.middleName" :label="fieldLabels.middleName">
                  <template #default="slotProps">
                    <b-form-input
                      v-bind="slotProps"
                      v-model="form.middleName"
                      trim
                      type="text"
                    />
                  </template>
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <form-group :validator="v$.form.lastName" :label="fieldLabels.lastName">
                  <template #default="slotProps">
                    <b-form-input
                      v-bind="slotProps"
                      v-model.trim="form.lastName"
                      type="text"
                    />
                  </template>
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <form-group :validator="v$.form.displayName" :label="fieldLabels.displayName">
                  <template #default="slotProps">
                    <key-multiselect
                      v-bind="slotProps"
                      v-model.trim="form.displayName"
                      :options="displayNameOptions"
                      taggable
                      label="label"
                      track-by="value"
                      :placeholder="defaultDisplayName"
                      select-label=""
                      deselect-label=""
                    />
                  </template>
                </form-group>
              </b-col>
            </b-row>
            <b-row v-if="isWorker">
              <b-col cols="12" sm="6" v-show="!singleOrgUnit">
                <form-group :validator="v$.form.orgUnit">
                  <template #label>
                    {{ fieldLabels.orgUnit }}
                    <help-text-icon>
                      Organization units can be added or edited under <b>Settings =&gt; Organization =&gt; Organization Units</b>.
                      You will still need to specify in the Extra Information section's Organization Unit Access box below at which org units employee can clock IN and OUT.
                    </help-text-icon>
                  </template>
                  <template #default="slotProps">
                    <key-multiselect
                      v-bind="slotProps"
                      v-model="form.orgUnit"
                      label="name"
                      track-by="id"
                      select-label=""
                      deselect-label=""
                      :options="sortedOrgUnits">
                    </key-multiselect>
                  </template>
                </form-group>
              </b-col>
              <b-col cols="12" sm="6" v-if="shouldShowEmployeeSensitiveField('pin')">
                <form-group :validator="v$.form.pin">
                  <template #label>
                    {{ fieldLabels.pin }}
                    <help-text-icon>
                      The pin is used to clock IN and OUT.
                      Allowed pin length for account can be changed under <b>Settings =&gt; Users =&gt; Defaults</b>.
                    </help-text-icon>
                  </template>
                  <template #default="slotProps">
                    <div class="pin-container">
                      <b-form-input
                        v-bind="slotProps"
                        v-model.trim="form.pin"
                        type="text" />
                      <b-btn variant="primary" @click="randomPin">
                        <font-awesome-icon icon="random" :rotation="180" />
                        Random Pin
                      </b-btn>
                      <br/>
                      <div v-if="v$.form.pin.$pending" class="invalid-feedback d-block">
                        <font-awesome-icon icon="circle-notch" spin /> Checking if pin is unique...
                      </div>
                    </div>
                  </template>
                </form-group>
              </b-col>
              <b-col cols="12" v-if="shouldShowEmployeeSensitiveField('pin') && qrCodeEnabled">
                <form-group :validator="v$.form.qrCode">
                  <template #label>
                    {{ fieldLabels.qrCode }}
                    <help-text-icon>
                      The QR Code is a type of bar code that can be used to clock IN and OUT.
                      The QR Code text below gets converted into a scannable image that can be printed and then presented at the time clock.
                      <br><br>
                      You can set the text for the QR code below.
                      But if you want to set this worker's QR code to an existing QR code image, you'll need to do that in the mobile app.
                    </help-text-icon>
                    <b-button v-if="!!form.qrCode" variant="link" style="margin-left: 2rem; text-decoration: none; padding: 0" @click="printQrCode">
                      Print
                      <font-awesome-icon icon="print" style="margin-left: .25rem" />
                    </b-button>
                  </template>
                  <template #default="slotProps">
                    <div class="qr-code-container">
                      <b-form-input
                        v-bind="slotProps"
                        v-model="form.qrCode"
                        trim
                        type="text" />
                      <b-btn variant="primary" @click="randomQrCode">
                        <font-awesome-icon icon="random" :rotation="180" />
                        Random QR code
                      </b-btn>
                      <br/>
                      <div v-if="v$.form.qrCode.$pending" class="invalid-feedback d-block">
                        <font-awesome-icon icon="circle-notch" spin /> Checking if QR Code is unique...
                      </div>
                    </div>
                  </template>
                </form-group>
              </b-col>
              <b-col cols="12" lg="6" v-if="shouldShowEmployeeSensitiveField('pin') && nfcTagEnabled">
                <form-group :validator="v$.form.nfcTagId">
                  <template #label>
                    {{ fieldLabels.nfcTagId }}
                    <help-text-icon>
                      The NFC Tag ID is the unique serial number of this worker's NFC Tag. It should be in hexadecimal format.
                      Typically, it wouldn't get manually entered here, but rather you would enroll the worker's NFC tag directly in the Fareclock app.
                      But you could manually enter it here if you happen to have collected this information outside of Fareclock.
                    </help-text-icon>
                  </template>
                  <template #default="slotProps">
                    <div class="nfc-tag-container">
                      <b-form-input
                        v-bind="slotProps"
                        v-model="form.nfcTagId"
                        trim
                        type="text" />
                      <div v-if="v$.form.nfcTagId.$pending" class="invalid-feedback d-block">
                        <font-awesome-icon icon="circle-notch" spin /> Checking if NFC Tag ID is unique...
                      </div>
                    </div>
                  </template>
                </form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="isWorker">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordion2 :variant="v$.$validationGroups.extraInformationGroup.$invalid ? 'danger' : 'dark'">Extra Information</b-btn>
      </b-card-header>
      <b-collapse id="accordion2" ref="accordion2" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container>
              <b-row>
                <b-col cols="12" sm="6" v-if="isWorker && (sortedDepartments.length > 0 || requiredFields.includes('department'))">
                  <form-group :validator="v$.form.department">
                    <template #label>
                      {{ fieldLabels.department }}
                      <help-text-icon>
                        Department is used for reporting. It is an optional field.
                        Departments can be added or edited under <b>Settings =&gt; Organization =&gt; Departments</b>.
                      </help-text-icon>
                    </template>
                    <template #default="slotProps">
                      <key-multiselect
                        v-bind="slotProps"
                        v-model="form.department"
                        label="name"
                        track-by="id"
                        select-label=""
                        deselect-label=""
                        :options="sortedDepartments">
                      </key-multiselect>
                    </template>
                  </form-group>
                </b-col>
                <b-col cols="12" sm="6" v-if="isWorker && payrollEnabled && (sortedPayClasses.length > 0 || requiredFields.includes('pay_class')) && shouldShowEmployeeSensitiveField('pay_class')">
                  <form-group :validator="v$.form.payClass">
                    <template #label>
                      {{ fieldLabels.payClass }}
                      <help-text-icon>
                        Pay Class is used to calculate wages, overtime, and other advanced payroll features.
                        It is an optional field. A pay class can be added or edited under <b>Settings =&gt; Payroll =&gt; Pay Classes</b>.
                      </help-text-icon>
                    </template>
                    <template #default="slotProps">
                      <pay-class-select
                        v-bind="slotProps"
                        v-model="form.payClass"
                        :activeOnly="true"
                        :disabled="!canEditPayClass"
                      />
                    </template>
                  </form-group>
                </b-col>
                <b-col cols="12" sm="6" v-if="isWorker && shouldShowEmployeeSensitiveField('payroll_id')">
                  <form-group :validator="v$.form.payrollId">
                    <template #label>
                      {{ fieldLabels.payrollId }}
                      <help-text-icon>
                        Payroll ID is used in conjunction with exporting to certain payroll services such as ADP, Paychex and Quickbooks, in order to identify this employee.
                        In Quickbooks, this value can be empty if the employee name in Fareclock exactly matches the name in Quickbooks, including middle initial.
                        If the name does not exactly match, then the exact matching name should be specified here as Payroll ID.
                      </help-text-icon>
                    </template>
                    <template #default="slotProps">
                      <b-form-input
                        v-bind="slotProps"
                        v-model.trim="form.payrollId"
                        type="text"
                      />
                    </template>
                  </form-group>
                </b-col>
                <b-col cols="12" sm="6" v-if="isWorker && scheduleEnabled && (sortedTeamSchedules.length > 0 || requiredFields.includes('team_schedule'))">
                  <form-group :validator="v$.form.teamSchedule">
                    <template #label>
                      {{ fieldLabels.teamSchedule }}
                      <help-text-icon>
                        Teams maybe added under Manage -> Schedule -> Teams.
                        Then you can set up shift schedules by team.
                      </help-text-icon>
                    </template>
                    <template #default="slotProps">
                      <key-multiselect
                        v-bind="slotProps"
                        v-model="form.teamSchedule"
                        label="name"
                        track-by="id"
                        select-label=""
                        deselect-label=""
                        :options="sortedTeamSchedules">
                      </key-multiselect>
                    </template>
                  </form-group>
                </b-col>
                <b-col cols="12" sm="6" v-if="isWorker && canViewWorkerTimeOffPolicy && (sortedTimeOffPolicies.length > 0 || requiredFields.includes('time_off_policy'))">
                  <form-group :validator="v$.form.timeOffPolicy">
                    <template #label>
                      {{ fieldLabels.timeOffPolicy }}
                      <help-text-icon>
                        A Time Off Policy is used to specify time off accrual rules.
                        It is an optional field. A time off policy can be added or edited under <b>Settings =&gt; Time Off =&gt; Policies</b>.
                      </help-text-icon>
                    </template>
                    <template #default="slotProps">
                      <key-multiselect
                        v-bind="slotProps"
                        v-model="form.timeOffPolicy"
                        label="name"
                        track-by="id"
                        select-label=""
                        deselect-label=""
                        :options="sortedTimeOffPolicies">
                      </key-multiselect>
                    </template>
                    <template v-if="form.timeOffPolicy && (!form.payClass || !payRunEnabled)" #description>
                      <div style="margin-top: .5rem">
                        <font-awesome-icon icon="triangle-exclamation" />
                        You must assign this worker a Pay Class and enable Pay Runs in order to apply automatic time off accrual and rollover policy.
                        For more information on Pay Runs, check out the
                        <a href="https://support.fareclock.com/hc/en-us/articles/360056736914-How-to-generate-Pay-Runs" target="_blank">Help Article</a>
                        on that topic.
                      </div>
                    </template>
                  </form-group>
                </b-col>
                <b-col cols="12" sm="6">
                  <form-group :validator="v$.form.labels">
                    <template #label>
                      Labels
                      <help-text-icon>
                        Labels may be defined under Settings => Organization => Labels.
                      </help-text-icon>
                    </template>
                    <template #default="slotProps">
                      <label-select
                        v-bind="slotProps"
                        v-model="form.labels"
                        :id="form.id"
                        labelType="user"
                      />
                    </template>
                  </form-group>
                </b-col>
                <b-col cols="12" sm="6" v-if="isWorker">
                  <form-group>
                    <template #label>
                      Announcement
                      <help-text-icon>
                        Announcement displays on time clock after an employee logs in.
                        May also be customized for entire organization and/or each clock, either in addition to this message or by override.
                      </help-text-icon>
                    </template>
                    <b-form-textarea
                            v-model="form.announcement"
                            :rows="3"
                            :max-rows="6">
                    </b-form-textarea>
                    <b-form-checkbox
                            v-model="form.announcementOverride"
                            :value="true"
                            :unchecked-value="false">
                      Announcement override
                    </b-form-checkbox>
                  </form-group>
                </b-col>
              </b-row>
            </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-if="isWorker && customFieldValues.length > 0">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordionCustomFields :variant="v$.$validationGroups.customFieldsGroup.$invalid ? 'danger' : 'dark'">Custom Fields</b-btn>
      </b-card-header>
      <b-collapse id="accordionCustomFields" ref="accordionCustomFields" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container>
            <template v-for="(customField, index) in allCustomFields" :key="customField.id">
              <b-row v-if="index === userCustomFields.length">
                <b-col cols="12" sm="6">
                  <div class="custom-field-default-values-header">
                    <span class="text">Default Values</span>
                    <help-text-icon>
                      The following values will be used as defaults for Punch or Shift custom fields.
                      If you don't want a default values, then leave blank.
                    </help-text-icon>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="6">
                  <!-- TODO: Can we switch to CustomFieldInputs? -->
                  <form-group
                    :label="customField.name"
                    :description="customField.helpText"
                    :validator="v$.customFieldValues"
                    :listItemIndex="index"
                    listItemPRoperty="value"
                  >
                    <template #default="slotProps">
                      <div>
                        <boolean-radio-group
                          v-if="customField.type === 'bool'"
                          v-bind="slotProps"
                          v-model="customFieldValues[index].value"
                          :showClearButton="customField.default.value === null"
                          :trueText="customField.boolTrueLabel"
                          :falseText="customField.boolFalseLabel"
                        />
                        <custom-field-item-select
                          v-else-if="customField.type === 'choice'"
                          v-bind="slotProps"
                          v-model="customFieldValues[index].value"
                          :customField="customField.id"
                          :name="customField.name"
                          :multiple="customField.listMultiple"
                        />
                        <b-form-textarea
                          v-else-if="customField.stringMultiline"
                          v-bind="slotProps"
                          v-model.trim="customFieldValues[index].value"
                          rows="3"
                          max-rows="3"
                        />
                        <b-form-input
                          v-else
                          v-bind="slotProps"
                          v-model.trim="customFieldValues[index].value"
                          :type="customField.type === 'number' ? 'number' : 'text'"
                          :number="customField.type === 'number'"
                        />
                        <div v-if="v$.customFieldValues.$each.$response.$data[index].value.$pending" class="invalid-feedback d-block">
                          <font-awesome-icon icon="circle-notch" spin /> Checking if {{ customField.name }} is unique...
                        </div>
                      </div>
                    </template>
                  </form-group>
                </b-col>
              </b-row>
            </template>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="!isOwner && (isAdmin || !singleOrgUnit)">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordionPermissions :variant="v$.$validationGroups.permissionsGroup.$invalid ? 'danger' : 'dark'">Permissions</b-btn>
      </b-card-header>
      <b-collapse id="accordionPermissions" ref="accordionPermissions" :accordion="accordionId" role="tabpanel">
        <b-card-body class="permissions">
          <b-container>
              <b-row v-if="!singleOrgUnit && isWorker">
                <b-col xl="8" lg="10" md="10" cols="12">
                  <div class="title">Worker Role Permissions</div>
                  <b-card sub-title="Device fields">
                    <form-group
                      :validator="v$.form.workerAllowLabels"
                      label-cols="12" label-cols-sm="4" label-cols-lg="4" label-cols-xl="4"
                    >
                      <template #label>
                        Org Units
                        <help-text-icon>
                          The worker will be able to access all time clock devices belonging to the org units specified here.
                        </help-text-icon>
                      </template>
                      <template #default="slotProps">
                        <org-unit-select
                          v-bind="slotProps"
                          v-model="workerAllowOrgUnits"
                          labelType="device"
                          :multiple="true"
                          :disabled="isOwner"
                          placeholder="All Org Units Allowed"
                          style="max-width: 20rem;"
                        />
                      </template>
                    </form-group>
                    <div v-if="!allowsHomeOrgUnit && !v$.form.workerAllowLabels.$invalid" class="error-message" style="text-align: left;">
                      <font-awesome-icon class="icon" icon="triangle-exclamation" />
                      <span>
                        These permitted Org Units are not including this user's home Org Unit "{{ orgUnitName }}".
                        It is *highly* recommended in most cases that you allow the home org unit, or it could
                        prevent this user from being able to Clock IN.
                      </span>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
              <b-row v-if="isAdmin">
                <b-col xl="8" lg="10" md="10" cols="12">
                  <div class="title">Admin Role Permissions</div>
                  <!-- TODO: for owner, either disable or hide -->
                  <field-conditions
                    v-model="form.adminAllowLabels"
                    :parentId="originalData.id"
                    :enableUserCustomFields="true"
                    :enableWorkFields="true"
                    :enableUserOrgUnit="true"
                    :enableWorkOrgUnit="true"
                    :setAll="false"
                    userMode="orguser"
                    fieldSeparator="AND"
                    @validityChanged="adminAllowLabelsValid = $event"
                  >
                    <template #device-org-unit-help-text>
                      This field controls which device org units the admin can view
                      Clock Logs and Raw Punches for, and which devices the admin can
                      set up and administer.
                    </template>
                    <template #user-org-unit-help-text>
                      This field controls which worker org units the admin can view
                      Workers and their associated data for.
                    </template>
                    <template #user-department-help-text>
                      This field controls which worker departments the admin can view
                      Workers and their associated data for.
                    </template>
                    <template #work-org-unit-help-text>
                      This field controls which combined worker and device org units the admin
                      view Clock Logs and Raw Punches.
                    </template>
                  </field-conditions>
                </b-col>
              </b-row>
              <b-row v-if="isAdmin">
                <b-col cols="12" sm="12">
                  <b-form-checkbox-group
                    v-model="form.permissions"
                    ref="permissions"
                    class="checkbox-group checkbox-group-permissions"
                    :validator="v$.form.permissions"
                    :disabled="isOwner"
                  >
                    <b-form-checkbox value="manage_subscription" disabled v-show="!isWhiteLabelUser">
                      Manage billing
                      <help-text-icon>
                        Permission to manage billing is set automatically for users with <code>Account Owner</code> role.
                      </help-text-icon>
                    </b-form-checkbox>
                    <b-form-checkbox value="edit_settings">
                      Edit organization settings
                      <help-text-icon>
                        Permission includes editing organization-level settings such as: defaults, API access,
                        organization information, departments, payroll and shift settings.
                      </help-text-icon>
                    </b-form-checkbox>
                    <b-form-checkbox value="edit_self">
                      Edit own information
                      <help-text-icon>
                        With this permission, this user may edit his or her own employee information and punches,
                        depending on whether this user also has the <code>Edit employees</code> and <code>Edit punches</code>
                        permissions.
                      </help-text-icon>
                    </b-form-checkbox>

                    <div class="line-break" />
                    <p class="line-break">
                      The following permissions only apply to the allowed fields for this user, as set above:
                    </p>
                    <div class="line-break" />

                    <b-form-checkbox value="edit_employees" :disabled="isSelf">
                      Edit workers
                      <help-text-icon>
                        Permission to edit workers also includes adding punch photos to a user's face model,
                        as well as access on time clock device to user data and face training.
                      </help-text-icon>
                    </b-form-checkbox>
                    <b-form-checkbox value="access_employee_sensitive" :disabled="!form.permissions.includes('edit_employees')">
                      User sensitive
                      <help-text-icon>
                        Permission to access a user's sensitive information.
                        This includes viewing Compensation, Identification, and Work History; editing this information or Pay Class;
                        deleting employees; and viewing wage totals in reports.
                      </help-text-icon>
                    </b-form-checkbox>
                    <b-form-checkbox value="edit_org_users" :disabled="isSelf || !form.permissions.includes('edit_employees')">
                      Edit administrators
                      <help-text-icon>
                        This permission allows access to view and edit user details. It does not control access to
                        administrator punches. Access to administrator punches is determined by the <code>Edit Punches</code>
                        permission.<br/>
                        In order to edit administrators, you also must have permission to <code>Edit Workers</code>.
                        Only account owners may edit other account owners.
                      </help-text-icon>
                    </b-form-checkbox>

                    <b-form-checkbox value="edit_clocks">Edit devices</b-form-checkbox>
                    <b-form-checkbox value="edit_jobs" :disabled="!costingEnabled">Edit jobs</b-form-checkbox>
                    <b-form-checkbox value="edit_punches">Edit punch times</b-form-checkbox>
                    <b-form-checkbox value="edit_punch_attributes" :disabled="form.permissions.includes('edit_punches')">
                      Edit punch attributes
                      <help-text-icon>
                        This permission will allow the administrator to view punches, and edit punch attributes such as job costing and notes.
                        It also allows approving flagged punches.
                        It does not allow this admin to add punches or edit punch times, unless the "Edit punch times" permission
                        is also granted.
                      </help-text-icon>
                    </b-form-checkbox>
                    <b-form-checkbox value="manage_clock_logs">
                      Manage clock logs
                      <help-text-icon>
                        If enabled, this user will be able to view and approve clock logs.
                        A clock log may be associated with a punch, so approving or rejecting
                        a clock log will affect its associated punch as well.
                        Approval permission also will update the employee's face model
                        if there are photos. The Geo Mapping feature will also be accessible with this permission.
                        This permission does not give access to editing or adding punch times.
                      </help-text-icon>
                    </b-form-checkbox>

                    <b-form-checkbox value="manage_discipline" :disabled="!disciplineEnabled">Manage attendance</b-form-checkbox>
                    <b-form-checkbox value="manage_payroll" :disabled="!payRunEnabled">Manage payroll</b-form-checkbox>
                    <b-form-checkbox value="manage_pto" :disabled="!timeOffEnabled">Manage time off</b-form-checkbox>
                    <b-form-checkbox value="manage_schedule" :disabled="!scheduleEnabled">Manage schedule</b-form-checkbox>
                    <b-form-checkbox value="setup_clocks">Setup clocks</b-form-checkbox>
                    <b-form-checkbox value="view_audit">View audit logs</b-form-checkbox>
                    <b-form-checkbox value="view_reports">View reports</b-form-checkbox>
                  </b-form-checkbox-group>
                  <div class="invalid-feedback" style="display: block" v-if="v$.form.permissions.$invalid">
                    {{ v$.form.permissions.$silentErrors[0].$message }}
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="isAdmin && !isOwner && canRestrictJobAccessByUser">
                <b-col cols="12" sm="6">
                  <form-group>
                    <b-form-checkbox v-model="form.requireJobAccess" :disabled="isSelf">
                      {{ fieldLabels.requireJobAccess }}
                      <help-text-icon>
                        If checked, then you'll need to specify which jobs this admin access access.
                        If not checked, then the admin will automatically be able to access all jobs.
                      </help-text-icon>
                    </b-form-checkbox>
                  </form-group>
                </b-col>
              </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="showNotifications">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordionNotifications :variant="v$.$validationGroups.notificationsGroup.$invalid ? 'danger' : 'dark'">Notifications</b-btn>
      </b-card-header>
      <b-collapse id="accordionNotifications" ref="accordionNotifications" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container>
              <b-row v-if="isAdmin">
                <b-col cols="12">
                  <b-form-checkbox-group
                    v-model="form.notifications"
                    class="checkbox-group"
                    :validator="v$.form.notifications"
                  >
                    <b-form-checkbox v-for="notificationOption in notificationOptions" :value="notificationOption.value" :key="notificationOption.value" :disabled="!!notificationOption.disabled">
                      {{ notificationOption.label }}
                      <help-text-icon v-if="notificationOption.helpText">{{ notificationOption.helpText }}</help-text-icon>
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                  <div class="invalid-feedback" style="display: block" v-if="v$.form.notifications.$invalid">
                    {{ v$.form.notifications.$silentErrors[0].$message }}
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" v-if="showEmailSuppression">
                  <div v-if="isAllEmailSuppressed" class="suppression-warning">
                    <font-awesome-icon icon="triangle-exclamation" />
                    {{ getSuppressionReason('email') }}
                    <help-text-icon>Contact Fareclock Support if you need help unblocking emails.</help-text-icon>
                  </div>
                  <div v-else-if="originalData.id && !isBillingEmailUnsubscribed && !isDeviceAlertEmailUnsubscribed && !isDisciplineAlertEmailUnsubscribed && !isScheduledReportEmailUnsubscribed">
                    <font-awesome-icon icon="circle-check" />
                    This user is able to receive email notifications.
                  </div>
                  <div v-if="isBillingEmailUnsubscribed" class="suppression-warning">
                    <font-awesome-icon icon="triangle-exclamation" />
                    This user unsubscribed from billing emails
                    <help-text-icon>Instruct user to resubscribe using the link at the bottom of an old Fareclock email.</help-text-icon>
                  </div>
                  <div v-if="isDeviceAlertEmailUnsubscribed" class="suppression-warning">
                    <font-awesome-icon icon="triangle-exclamation" />
                    This user unsubscribed from device alert emails
                    <help-text-icon>Instruct user to resubscribe using the link at the bottom of an old Fareclock email.</help-text-icon>
                  </div>
                  <div v-if="isDisciplineAlertEmailUnsubscribed" class="suppression-warning">
                    <font-awesome-icon icon="triangle-exclamation" />
                    This user unsubscribed from attendance alert emails
                    <help-text-icon>Instruct user to resubscribe using the link at the bottom of an old Fareclock email.</help-text-icon>
                  </div>
                  <div v-if="isScheduledReportEmailUnsubscribed" class="suppression-warning">
                    <font-awesome-icon icon="triangle-exclamation" />
                    This user unsubscribed from report emails
                    <help-text-icon>Instruct user to resubscribe using the link at the bottom of an old Fareclock email.</help-text-icon>
                  </div>
                  <b-button v-if="isSudoUser && originalData.contactSuppressions && originalData.contactSuppressions.email" @click="openMessageContactModal('email')" variant="link" style="padding:0">
                    <font-awesome-icon icon="envelope" />
                    Edit email suppressions
                  </b-button>
                </b-col>
                <b-col cols="12" v-if="showSmsSuppression">
                  <div v-if="isAllSmsSuppressed" class="suppression-warning">
                    <font-awesome-icon icon="triangle-exclamation" />
                    {{ getSuppressionReason('sms') }}
                    <help-text-icon>Contact Fareclock Support if you need help unblocking text messaging.</help-text-icon>
                  </div>
                  <div v-else>
                    <font-awesome-icon icon="circle-check" />
                    This user is able to receive mobile text notifications
                  </div>
                  <b-button v-if="isSudoUser && originalData.contactSuppressions && originalData.contactSuppressions.sms" @click="openMessageContactModal('sms')" variant="link" style="padding:0">
                    <font-awesome-icon icon="message-sms" />
                    Edit sms suppressions
                  </b-button>
                </b-col>
              </b-row>
              <b-row v-if="hasNotificationFeature && originalData.id">
                <b-col>
                  <br>
                  <b-button @click="sendNotificationMessage" variant="link" style="padding:0">
                    <font-awesome-icon icon="bell" />
                    Send notification message to this user
                  </b-button>
                </b-col>
              </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="personalDeviceAllowed">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordionDeviceSettings :variant="v$.$validationGroups.personalDeviceGroup.$invalid ? 'danger' : 'dark'">Personal Device Settings</b-btn>
      </b-card-header>
      <b-collapse id="accordionDeviceSettings" ref="accordionDeviceSettings" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container>
              <b-row>
                <b-col cols="12" sm="6">
                    <b-form-checkbox
                            v-model="enablePersonalDevice"
                            :value="true"
                            :unchecked-value="false">
                      Enable personal device
                    </b-form-checkbox>
                </b-col>
              </b-row>
              <b-row v-if="originalData.personalDeviceMode && !enablePersonalDevice">
                <b-col cols="12" class="text-danger">
                  <font-awesome-icon icon="triangle-exclamation" style="margin-right: 5px" />
                  If changes are saved, then this user's personal device will no longer be able to use Fareclock.
                </b-col>
              </b-row>
              <b-row v-if="enablePersonalDevice">
                <b-col cols="12" md="5">
                  <form-group
                    :validator="v$.form.mobilePhone"
                    label="Mobile phone #"
                    label-for="mobile-phone"
                  >
                    <template #default="slotProps">
                      <div>
                        <e164-phone-number-input
                          ref="e164"
                          id="mobile-phone"
                          v-bind="slotProps"
                          v-model="form.mobilePhone"
                          @validityChanged="mobilePhoneValid = $event"
                        />
                        <div v-if="v$.form.mobilePhone && v$.form.mobilePhone.$pending" class="invalid-feedback d-block">
                          <font-awesome-icon icon="circle-notch" spin /> Checking if phone number is unique...
                        </div>
                      </div>
                    </template>
                  </form-group>
                </b-col>
                <b-col cols="12" md="3" class="device-uri-or">
                  &mdash; AND / OR &mdash;
                </b-col>
                <b-col cols="12" md="4" v-if="isAdmin">
                  <form-group label="Email">
                    <b-form-input
                      v-model.trim="form.email"
                      type="text"
                      readOnly
                    />
                  </form-group>
                </b-col>
                <b-col cols="12" md="4" v-else>
                  <form-group
                    :label="fieldLabels.email"
                    :validator="v$.form.email"
                  >
                    <template #default="slotProps">
                      <div>
                        <b-form-input
                          v-bind="slotProps"
                          v-model.trim="form.email"
                          type="email" />
                        <div v-if="v$.form.email.$pending" class="invalid-feedback d-block">
                          <font-awesome-icon icon="circle-notch" spin /> Checking if email is unique...
                        </div>
                      </div>
                    </template>
                  </form-group>
                </b-col>
                <b-col cols="12">
                    <form-group
                      :label="`${fieldLabels.personalDeviceMode}:`"
                      :validator="v$.form.personalDeviceMode"
                      class="no-validity-style"
                    >
                      <template #default="slotProps">
                        <b-form-radio-group
                          v-bind="slotProps"
                          v-model="form.personalDeviceMode"
                          stacked
                        >
                          <b-form-radio v-for="option in personalDeviceModeOptions"
                            :key="option.value"
                            :value="option.value"
                          >
                            {{ option.label }}
                            <help-text-icon>
                              {{ option.helpText }}
                            </help-text-icon>
                          </b-form-radio>
                        </b-form-radio-group>
                      </template>
                    </form-group>
                </b-col>
                <b-col cols="12" v-if="isWorker && !isAdmin">
                    <form-group :validator="v$.form.reinstallPerm">
                      <template #label>
                        {{ fieldLabels.reinstallPerm }}:
                        <help-text-icon>
                          This permission setting affects whether the user is reinstalling on a different device or the same device.
                          The <code>Personal Device</code> feature only allows for one installation at a time, so a reinstall
                          will automatically deactivate any other existing <code>Personal Device</code> installations for this user.
                          It does not affect any installations that may have been performed using administrator login.
                        </help-text-icon>
                      </template>
                      <template #default="slotProps">
                        <b-form-radio-group v-model="form.reinstallPerm"
                          v-bind="slotProps"
                          stacked
                        >
                          <b-form-radio v-for="option in reinstallPermOptions"
                            :key="option.value"
                            :value="option.value"
                          >
                            {{ option.label }}
                          </b-form-radio>
                        </b-form-radio-group>
                      </template>
                    </form-group>
                </b-col>
                <b-col cols="12" sm="6" v-if="geoEnabled && form.personalDeviceMode !== 'reports'">
                  <form-group>
                    <template #label>
                      Geo Fences
                      <help-text-icon>
                        A geo fence is an optional field, used to enforce the geographical location where this user can Clock IN or OUT.
                        Geo fences may be added under <b>Settings =&gt; Devices =&gt; Geo Fences</b>.
                      </help-text-icon>
                    </template>
                    <geo-rule-select
                      v-model="form.geoRules"
                      :id="form.id"
                    />
                  </form-group>
                </b-col>
                <b-col cols="12" sm="6" v-if="!singleOrgUnit && !isWorker">
                  <form-group :validator="v$.form.orgUnit">
                    <template #label>
                      {{ fieldLabels.orgUnit }}
                      <help-text-icon>
                        If workers are clocked IN on this admin's personal device, then the punch's device org unit will be set to this value.
                      </help-text-icon>
                    </template>
                    <template #default="slotProps">
                      <key-multiselect
                        v-bind="slotProps"
                        v-model="form.orgUnit"
                        label="name"
                        track-by="id"
                        select-label=""
                        deselect-label=""
                        :options="sortedOrgUnits">
                      </key-multiselect>
                    </template>
                  </form-group>
                </b-col>

                <b-col cols="12" v-if="originalData.personalDeviceMode && enablePersonalDevice">
                  <b-btn variant="secondary" @click="resendPersonalDeviceLink" v-if="originalData.installCode">
                    Resend link
                  </b-btn>
                  <b-btn
                    v-else
                    variant="secondary"
                    @click="reinstallPersonalDevice"
                    @disabled="creatingInstallCode"
                  >
                    Reset &amp; Reinstall
                  </b-btn>
                  <personal-device-send-link-modal
                    v-model="personalDeviceSendLinkModalVisible"
                    :orgUserId="originalData.id"
                    :mobilePhone="originalData.mobilePhone"
                    :email="originalData.email"
                    :installCode="originalData.installCode"
                    :isAllEmailSuppressed="isAllEmailSuppressed"
                    :isAllSmsSuppressed="isAllSmsSuppressed"
                  />
                </b-col>
              </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="isWorker && canViewWorkerTimeOffPolicy && originalData.timeOffBalances && originalData.timeOffPolicy">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordionTimeOffBalances variant="dark">Time Off Balances</b-btn>
      </b-card-header>
      <b-collapse id="accordionTimeOffBalances" ref="accordionTimeOffBalances" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container>
              <b-row>
                <b-col cols="12">
                  <time-off-balances
                    :value="originalData.timeOffBalances"
                    :workerId="originalData.id"
                    :workerName="formatName(originalData.firstName, originalData.lastName)"
                    @time-off-balances-updated="eventBus.emit('itemUpdated', originalData)"
                  />
                </b-col>
              </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="isWorker && disciplineEnabled">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordionDiscipline :variant="v$.$validationGroups.disciplineGroup.$invalid ? 'danger' : 'dark'">Attendance</b-btn>
      </b-card-header>
      <b-collapse id="accordionDiscipline" ref="accordionDiscipline" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container v-if="isWorker && disciplineEnabled">
              <b-row v-if="(sortedDisciplinePolicies.length > 0 || requiredFields.includes('discipline_policy'))">
                <b-col cols="12" sm="6">
                  <form-group :validator="v$.form.disciplinePolicy">
                    <template #label>
                      {{ fieldLabels.disciplinePolicy }}
                      <help-text-icon>
                        Attendance Policies can be added at Settings -> Attendance -> Policies.
                      </help-text-icon>
                    </template>
                    <template #default="slotProps">
                      <key-multiselect
                        v-bind="slotProps"
                        v-model="form.disciplinePolicy"
                        label="name"
                        track-by="id"
                        select-label=""
                        deselect-label=""
                        :options="sortedDisciplinePolicies">
                      </key-multiselect>
                    </template>
                  </form-group>
                </b-col>
              </b-row>
              <b-row v-if="originalData.disciplinePolicy">
                <b-col cols="12" sm="6">
                  <form-group label="Point balance">
                    {{ form.pointBalance || 0 }}
                  </form-group>
                </b-col>
              </b-row>
              <b-row v-if="originalData.disciplinePolicy && scheduleEnabled">
                <b-col cols="12" sm="6">
                  <form-group label="Next shift reminder">
                    {{ nextShiftReminderDisplay }}
                  </form-group>
                </b-col>
              </b-row>
            </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!-- face model card doesn't work right with v-if. not sure why. -->
    <b-card no-body class="mb-1" v-show="faceModelVisible">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordion3 variant="dark">Face Model</b-btn>
      </b-card-header>
      <b-collapse id="accordion3" ref="accordion3"
        :accordion="accordionId"
        role="tabpanel"
        v-model="faceModelCollapseOpen"
      >
        <b-card-body>
          <employee-face-model
            :employeeId="originalData.id"
            :employeeOrgUnit="originalData.orgUnit"
            :showing="faceModelVisible && faceModelCollapseOpen"
            @avatarChanged="avatarChanged" />
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="isWorker && shouldShowEmployeeSensitiveField('pay_rates')">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordionCompensation :variant="v$.$validationGroups.compensationGroup.$invalid ? 'danger' : 'dark'">Compensation</b-btn>
      </b-card-header>
      <b-collapse id="accordionCompensation" ref="accordionCompensation" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <pay-rate-list :value="form.payRates" :parentKey="form.id" :isFormOpen="isFormOpen" />
          <div v-if="v$.form.payRates && v$.form.payRates.$invalid" class="invalid-feedback d-block">
            <font-awesome-icon icon="triangle-exclamation" /> At least one Pay Rate must be specified.
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="isWorker && netPayEnabled && canManagePayroll">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordionNetPay :variant="v$.$validationGroups.netPayGroup.$invalid ? 'danger' : 'dark'">Net Pay</b-btn>
      </b-card-header>
      <b-collapse id="accordionNetPay" ref="accordionNetPay" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container v-if="isWorker && form.bankInfo">
            <b-row>
              <b-col cols="12" sm="6">
                <form-group :validator="v$.form.bankInfo.name" label="Bank name">
                  <template #default="slotProps">
                    <b-form-input
                      v-bind="slotProps"
                      v-model.trim="form.bankInfo.name"
                      type="text"
                    />
                  </template>
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <form-group :validator="v$.form.bankInfo.accountNo" label="Bank account number">
                  <template #default="slotProps">
                    <b-form-input
                      v-bind="slotProps"
                      v-model.trim="form.bankInfo.accountNo"
                      type="text"
                    />
                  </template>
                </form-group>
              </b-col>
            </b-row>
            <b-row v-if="form.id">
              <b-col cols="12" sm="6">
                <router-link :to="netPayAdjustmentLink" target="_blank">
                  <font-awesome-icon icon="landmark" class="icon" />
                  View Net Pay Adjustments
                </router-link>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="isWorker">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordion4 variant="dark">Contact Details</b-btn>
      </b-card-header>
      <b-collapse id="accordion4" ref="accordion4" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row>
              <b-col cols="12" sm="6">
                <form-group label="Address">
                  <b-form-textarea
                    v-model="form.address"
                    :rows="3"
                    :max-rows="3"
                    :disabled="verifyingAddress"
                    @update:modelValue="addressChanged">
                  </b-form-textarea>
                </form-group>
                <div v-if="geoEnabled" class="address-mapping">
                  <template v-if="form.addressGeoCode && form.addressGeoCode.lat">
                    <div class="verified-address" key="verified-address">
                      <font-awesome-icon icon="location-dot" />
                      <div class="text">
                        {{ form.addressGeoCode.formattedAddress }}<br>
                        Coords: {{ form.addressGeoCode.lat }}&deg;, {{ form.addressGeoCode.lng }}&deg;
                      </div>
                    </div>
                    <div class="verified" key="verified">
                      <font-awesome-icon icon="circle-check" />
                      Address verified
                    </div>
                  </template>
                  <template v-else>
                    <!-- TODO: Only show error on left with link on right if there was a network error. -->
                    <div v-if="addressVerifyError" class="not-verified" key="not-verified-error">
                      <font-awesome-icon icon="triangle-exclamation" />
                      Address could not be verified
                    </div>
                    <div v-else key="not-verified-spacer"></div>
                    <div v-if="verifyingAddress" class="verifying">
                      <font-awesome-icon icon="circle-notch" spin />
                      Verifying address...
                    </div>
                    <b-button v-else-if="form.address" class="verify" @click="verifyAddress" key="verify" variant="link" style="padding:0">
                      <font-awesome-icon icon="map-location-dot" />
                      Verify address
                    </b-button>
                  </template>
                </div>
              </b-col>
              <b-col cols="12" sm="6">
                <form-group label="Phone #1">
                  <phone-number-input v-model="phone1" />
                </form-group>
                <form-group label="Phone #2">
                  <phone-number-input v-model="phone2" />
                </form-group>
                <form-group :validator="v$.form.email" v-if="!isAdmin" :label="fieldLabels.email">
                  <template #default="slotProps">
                    <b-form-input
                      v-bind="slotProps"
                      v-model.trim="form.email"
                      type="text"
                    />
                  </template>
                </form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-if="isWorker && canAccessCredentials" v-show="healthAndSafetyVisible">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordionCredentials variant="dark">Health & Safety</b-btn>
      </b-card-header>
      <b-collapse id="accordionCredentials" ref="accordionCredentials"
        :accordion="accordionId"
        role="tabpanel"
        v-model="credentialsCollapseOpen"
      >
        <b-card-body>
          <b-container>
              <b-row>
                <b-col cols="12" sm="6" v-if="sortedCredentialPolicies.length > 0 || requiredFields.includes('credential_policy')">
                  <form-group :validator="v$.form.credentialPolicy">
                    <template #label>
                      {{ fieldLabels.credentialPolicy }}
                      <help-text-icon>
                        Assign a Health & Safety Policy in order to require certain Credentials in order to Clock IN to work.
                        If not specified, you can still assign Ad Hoc Credentials below without a policy, but an invalid
                        Ad Hoc Credential will not prevent a worker from clocking IN.
                      </help-text-icon>
                    </template>
                    <template #default="slotProps">
                      <key-multiselect
                        v-bind="slotProps"
                        v-model="form.credentialPolicy"
                        label="name"
                        track-by="id"
                        select-label=""
                        deselect-label=""
                        :options="sortedCredentialPolicies">
                      </key-multiselect>
                    </template>
                  </form-group>
                </b-col>
                <b-col cols="12" sm="6" v-if="originalData.credentialPolicy">
                  <form-group label="Status">
                    <div v-if="originalData.invalidCredentialing" class="credential-status error">
                      <font-awesome-icon icon="circle-exclamation"/>
                      <span>Invalid</span>
                    </div>
                    <div v-else class="credential-status success">
                      <font-awesome-icon icon="circle-check" />
                      <span>Valid</span>
                    </div>
                  </form-group>
                </b-col>
              </b-row>
              <b-row v-if="originalData.credentialPolicy != form.credentialPolicy && credentialsEnabled">
                <b-col cols="12">
                  <b-alert show variant="warning">
                    <font-awesome-icon icon="circle-info" style="margin-right: 5px" />
                    Save this user in order to show or update Policy Credentials.
                  </b-alert>
                </b-col>
              </b-row>
              <b-row v-if="!addingNew && credentialsEnabled">
                <b-col cols="12">
                  <user-credentials
                    :orgUserId="originalData.id"
                    :credentialPolicyId="originalData.credentialPolicy"
                    :hasCredentials="originalData.hasCredentials"
                    :isFormOpen="isFormOpen"
                    @credentialing-validity-changed="onCredentialingValidityChanged"
                    @has-credentials-changed="onHasCredentialsChanged"
                    @has-documents-changed="onHasDocumentsChanged"
                    @user-credentials="userCredentials = $event"
                  />
                </b-col>
              </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-if="isWorker && canAccessDocuments" v-show="documentsVisible">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordionDocuments variant="dark">Documents</b-btn>
      </b-card-header>
      <b-collapse id="accordionDocuments" ref="accordionDocuments"
        :accordion="accordionId"
        role="tabpanel"
        v-model="documentsCollapseOpen"
      >
        <b-card-body>
          <b-container>
            <worker-documents
              :orgUserId="originalData.id"
              :isActiveWorker="originalData.active && originalData.roles.includes('worker')"
              :hasDocuments="originalData.hasDocuments"
              attachToType="USER"
              :useForm="false"
              :showing="documentsVisible && documentsCollapseOpen"
              :isFormOpen="isFormOpen"
              @has-documents-changed="onHasDocumentsChanged"
            />
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-if="enableBriefings" v-show="isWorker && !addingNew">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordionBriefings variant="dark">Briefings</b-btn>
      </b-card-header>
      <b-collapse id="accordionBriefings" ref="accordionBriefings"
        :accordion="accordionId"
        role="tabpanel"
        v-model="briefingsCollapseOpen"
      >
        <b-card-body>
          <b-container>
            <user-briefing-list v-if="isWorker && !addingNew"
              :orgUserId="originalData.id"
              :showing="briefingsCollapseOpen"
            />
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="isWorker">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordion5 :variant="v$.$validationGroups.identificationGroup.$invalid ? 'danger' : 'dark'">Identification</b-btn>
      </b-card-header>
      <b-collapse id="accordion5" ref="accordion5" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row>
              <b-col cols="12" sm="6">
                <form-group label="Gender" v-if="shouldShowEmployeeSensitiveField('gender')">
                  <key-multiselect
                    v-model.trim="form.gender"
                    :options="genderOptions"
                    taggable
                    label="label"
                    track-by="value"
                    placeholder=""
                    select-label=""
                    deselect-label=""
                    style="max-width: 250px"
                  />
                </form-group>
                <form-group label="Birth date" v-if="shouldShowEmployeeSensitiveField('birth_date')">
                  <date-picker v-model="form.birthDate" />
                </form-group>
                <form-group label="Nationality" v-if="shouldShowEmployeeSensitiveField('nationality')">
                  <b-form-input v-model.trim="form.nationality"
                              type="text"></b-form-input>
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <form-group
                  v-if="isWorker && shouldShowEmployeeSensitiveField('national_id')"
                  :validator="v$.form.nationalId"
                  :label="fieldLabels.nationalId"
                >
                  <template #default="slotProps">
                    <div>
                      <b-form-input
                        v-bind="slotProps"
                        v-model.trim="form.nationalId"
                        type="text"
                      />
                      <div v-if="v$.form.nationalId.$pending" class="invalid-feedback d-block">
                        <font-awesome-icon icon="circle-notch" spin /> Checking if National ID is unique...
                      </div>
                    </div>
                  </template>
                </form-group>

                <form-group label="Driver's license" v-if="shouldShowEmployeeSensitiveField('drivers_lic')">
                  <b-form-input v-model.trim="form.driversLic"
                              type="text"></b-form-input>
                </form-group>
                <form-group label="Driver's license expire date" v-if="shouldShowEmployeeSensitiveField('drivers_lic')">
                  <date-picker v-model="form.driversLicExpDate" />
                </form-group>
                <form-group label="Passport number" v-if="shouldShowEmployeeSensitiveField('passport')">
                  <b-form-input v-model.trim="form.passportNo"
                              type="text"></b-form-input>
                </form-group>
                <form-group label="Passport expire date" v-if="shouldShowEmployeeSensitiveField('passport')">
                  <date-picker v-model="form.passportExpDate" />
                </form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="isWorker">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordionWorkHistory :variant="v$.$validationGroups.workHistoryGroup.$invalid ? 'danger' : 'dark'">Work History</b-btn>
      </b-card-header>
      <b-collapse id="accordionWorkHistory" ref="accordionWorkHistory" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row>
              <b-col cols="12" sm="6">
                <form-group label="Job title" v-if="shouldShowEmployeeSensitiveField('job_title')">
                  <b-form-input v-model.trim="form.jobTitle"
                              type="text"></b-form-input>
                </form-group>
                <form-group label="Hire date" v-if="shouldShowEmployeeSensitiveField('hire_date')"
                  :validator="v$.form.hireDate"
                >
                  <template #default="slotProps">
                    <date-picker v-model="form.hireDate"
                      v-bind="slotProps"
                    />
                  </template>
                </form-group>
                <form-group label="Hire by" v-if="shouldShowEmployeeSensitiveField('hire_by')">
                  <b-form-input v-model.trim="form.hireBy"
                              type="text"></b-form-input>
                </form-group>
                <form-group label="Termination date" v-if="shouldShowEmployeeSensitiveField('term_date')" >
                  <date-picker v-model="form.termDate" />
                </form-group>
              </b-col>
              <b-col cols="12" sm="6" v-if="isWorker && shouldShowEmployeeSensitiveField('notes')">
                <form-group label="Notes">
                  <b-form-textarea
                    v-model="form.notes"
                    :rows="3"
                    :max-rows="6">
                  </b-form-textarea>
                </form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="isWorker && shouldShowEmployeeSensitiveField('emg_contact')">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordion7 variant="dark">Emergency Contact</b-btn>
      </b-card-header>
      <b-collapse id="accordion7" ref="accordion7" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row>
              <b-col cols="12" sm="6">
                <form-group label="Name">
                  <b-form-input v-model.trim="form.emgContact"
                    type="text"></b-form-input>
                </form-group>
                <form-group label="Relationship" >
                  <relationship-selector v-model="form.emgRelation" />
                </form-group>
                <form-group label="Address">
                  <b-form-textarea
                                  v-model="form.emgAddress"
                                  :rows="3"
                                  :max-rows="6">
                  </b-form-textarea>
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <form-group label="Phone #1">
                  <phone-number-input v-model="emgPhone1" />
                </form-group>
                <form-group label="Phone #2">
                  <phone-number-input v-model="emgPhone2" />
                </form-group>
                <form-group :validator="v$.form.emgEmail" label="Email">
                  <template #default="slotProps">
                    <b-form-input
                      v-bind="slotProps"
                      v-model.trim="form.emgEmail"
                      type="text"
                    />
                  </template>
                </form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="!addingNew">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordion8 variant="dark">Status</b-btn>
      </b-card-header>
      <b-collapse id="accordion8" ref="accordion8" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-container>
            <b-row>
              <b-col cols="12" sm="6">
                <form-group label="Last punch">
                  {{ lastPunch }}
                </form-group>
              </b-col>
              <b-col cols="12" sm="6" v-if="originalData.accepted">
                <form-group label="Last signed in">
                  {{ lastAccess }}
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <form-group label="Created">
                  {{ created }}
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <form-group label="Last modified">
                  {{ modified }}
                </form-group>
              </b-col>
              <b-col cols="12" sm="6" v-if="isWorker">
                <form-group label="Inducted">
                  <b-form-checkbox v-model="form.inducted" />
                </form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <b-button variant="outline-primary" size="sm" @click="downloadDataForm" :disabled="formDirty">
                  <font-awesome-icon icon="file-arrow-down" />
                  Download data form
                </b-button>
                <div v-if="formDirty" class="download-data-form-disabled-message">
                  <font-awesome-icon icon="circle-info" />
                  You must save changes before you can download the data form.
                </div>
              </b-col>
              <b-col cols="12" sm="6" v-if="canViewAudit && form.id">
                <form-group label="Audit logs">
                  <audit-links
                    :instanceId="form.id"
                    kind="ORGUSER"
                    actorTitle="View changes made by this user"
                    affectedTitle="View changes made to this user"
                    associatedTitle="View changes made to this user's time and attendance data"
                  />
                </form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1" v-show="!addingNew && canDeleteEmployee && !isSelf && !isOwner">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block v-b-toggle.accordion9 variant="danger">Danger Zone</b-btn>
      </b-card-header>
      <b-collapse id="accordion9" ref="accordion9" :accordion="accordionId" role="tabpanel">
        <b-card-body>
          <b-button variant="danger" @click="deleteItem" v-if="!isOwner" :disabled="saving" style="margin-right: 1rem; margin-bottom: 1rem">
            <font-awesome-icon icon="user-minus" />
            Delete User
          </b-button>
          <template v-if="!isOwner && canAccessEmployeeSensitiveField('banned')">
            <b-button
              :disabled="formDirty || saving"
              @click="toggleBan"
              variant="danger"
              style="margin-right: 1rem; margin-bottom: 1rem"
            >
              <font-awesome-icon :icon="originalData.banned ? 'user-unlock' : 'ban'" />
              {{ originalData.banned ? 'Unlock Ban on' : 'Ban' }} User
            </b-button>
            <span v-if="formDirty" style="font-size: 0.8rem; font-style: italic;">* Save your changes before changing Ban status.</span>
          </template>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import AuditLinks from '@/components/AuditLinks.vue'
import BooleanRadioGroup from '@/components/BooleanRadioGroup.vue'
import DatePicker from '@/components/DatePicker.vue'
import FieldConditions from '@/components/FieldConditions.vue'
import HelpTextIcon from '@/components/HelpTextIcon.vue'
import KeyMultiselect from '@/components/KeyMultiselect.vue'
import OrgUnitSelect from '@/components/OrgUnitSelect.vue'
import PhoneNumberInput from '@/components/PhoneNumberInput.vue'
import CustomFieldItemSelect from '@/components/CustomFieldItemSelect.vue'
import E164PhoneNumberInput from '@/components/E164PhoneNumberInput.vue'
import RelationshipSelector from '@/components/RelationshipSelector.vue'
import DeleteUserModal from './DeleteUserModal.vue'
import EmployeeFaceModel from './EmployeeFaceModel.vue'
import PayRateList from './PayRateList.vue'
import PersonalDeviceSendLinkModal from './PersonalDeviceSendLinkModal.vue'
import TimeOffBalances from './TimeOffBalances.vue'
import UserBriefingList from './credential/UserBriefingList.vue'
import UserCredentials from './credential/UserCredentials.vue'
import UserRoleSelect from './UserRoleSelect.vue'
import WorkerDocuments from './WorkerDocuments.vue'
import { helpers, email, integer, minLength, maxLength, required, requiredIf } from '@vuelidate/validators'
import { mapGetters, mapMutations, mapState } from 'vuex'
import _ from 'lodash'
import employeeService from './services/EmployeeService'
import { debounceAsyncValidator } from '@/utils/debounce'
import { extractErrorMessage, hasValue, randomDigits, randomText } from '@/utils/misc'
import Accordion from '@/mixins/Accordion'
import personalDeviceModeOptions from './PersonalDeviceModeOptions'
import reinstallPermOptions from './ReinstallPermOptions'
import GeoRuleSelect from '@/views/settings/devices/GeoRuleSelect.vue'
import { toQrCodeDataURL } from '@/utils/qrcode'
import { printWindow } from '@/utils/print'
import DetailBasedForm from '@/mixins/DetailBasedForm'
import LabelSelect from '@/views/settings/organization/LabelSelect.vue'
import PayClassSelect from '@/views/settings/payroll/PayClassSelect.vue'
import MessageContactModal from '@/views/manage/messaging/MessageContactModal.vue'
import SendNotificationModal from './SendNotificationModal.vue'
import { useVuelidate } from '@vuelidate/core'
import { email as isEmail } from '@vuelidate/validators/dist/raw'
import { useModalController } from 'bootstrap-vue-next'

const BILLING_EMAIL_GROUP = '24574'
const DEVICE_ALERT_EMAIL_GROUP = '25032'
const DISCIPLINE_ALERT_EMAIL_GROUP = '28601'
const REPORT_EMAIL_GROUP = '24366'

const NewUser = () => ({
  roles: ['worker'],
  active: true,
  labels: [],
  workerAllowLabels: [],
  adminAllowLabels: [],
  requireJobAccess: false,
  permissions: [],
  notifications: [],
  announcementOverride: false,
  orgUnit: null,
  personalDeviceMode: null,
  reinstallPerm: null,
  mobilePhone: null,
  installCode: null,
  geoRules: [],
  pin: null,
  qrCode: null,
  nfcTagId: null,
  payRates: [],
  banned: false,
  address: null,
  addressGeoCode: null,
  customFieldValues: [],
  bankInfo: {
    name: null,
    accountNo: null
  }
})

export default {
  setup () {
    const modalController = useModalController()
    return {
      v$: useVuelidate({ $scope: false, $stopPropagation: true }),
      confirmModal: modalController.confirm,
      showModal: modalController.show
    }
  },
  components: {
    AuditLinks,
    BooleanRadioGroup,
    CustomFieldItemSelect,
    DatePicker,
    EmployeeFaceModel,
    FieldConditions,
    GeoRuleSelect,
    HelpTextIcon,
    KeyMultiselect,
    LabelSelect,
    OrgUnitSelect,
    PayClassSelect,
    PayRateList,
    PersonalDeviceSendLinkModal,
    PhoneNumberInput,
    RelationshipSelector,
    UserRoleSelect,
    E164PhoneNumberInput,
    TimeOffBalances,
    UserBriefingList,
    UserCredentials,
    WorkerDocuments
  },
  mixins: [
    Accordion,
    DetailBasedForm
  ],
  inheritAttrs: false,
  data () {
    return {
      briefingsCollapseOpen: false,
      credentialsCollapseOpen: false,
      documentsCollapseOpen: false,
      faceModelCollapseOpen: false,
      form: NewUser(),
      fieldLabels: {
        roles: 'Roles',
        firstName: 'First name',
        middleName: 'Middle name',
        lastName: 'Last name',
        displayName: 'Display name',
        email: 'Email',
        pin: 'Pin',
        qrCode: 'QR code',
        nfcTagId: 'NFC Tag ID',
        orgUnit: 'Org unit',
        department: 'Department',
        disciplinePolicy: 'Attendance policy',
        payrollId: 'Payroll ID',
        requireJobAccess: 'Required job access for admin',
        credentialPolicy: 'Health & Safety policy',
        payClass: 'Pay class',
        timeOffPolicy: 'Time off policy',
        permissions: 'Permissions',
        emgEmail: 'Emergency email',
        compensation: 'Compensation',
        personalDeviceMode: 'Personal device mode',
        reinstallPerm: 'Reinstall permission',
        teamSchedule: 'Team Schedule',
        nationalId: 'National ID / SSN'
      },
      resendingInvite: false,
      enablePersonalDevice: false,
      mobilePhoneValid: false,
      creatingInstallCode: false,
      reinstallPermOptions: Object.freeze(
        Object.entries(reinstallPermOptions)
          .map(entry => Object.assign({ value: entry[0] }, entry[1]))
      ),
      genderOptions: [
        { value: 'male', label: 'Male'},
        { value: 'female', label: 'Female'},
      ],
      verifyingAddress: false,
      addressVerifyError: null,
      userCredentials: null,
      customFieldValues: [],
      workerAllowOrgUnits: [],
      adminAllowLabelsValid: true,
      personalDeviceSendLinkModalVisible: false
    }
  },
  watch: {
    originalData: {
      handler (newValue, oldValue) {
        if (!_.get(newValue, 'id') || _.get(newValue, 'id') !== _.get(oldValue, 'id')) {
          this.userCredentials = null
        }

        let form
        if (_.isEmpty(newValue)) {
          form = NewUser()

          this.enablePersonalDevice = false
          form.workerAllowLabels = this.adminAllowUserOrgUnits.map(orgUnitId => `device:orgunit:${orgUnitId}`)

          // Pre-populate required fields that have exactly one value.
          if (this.requiredFields.includes('pay_class') && this.sortedPayClasses.length === 1) {
            form.payClass = this.sortedPayClasses[0].id
          }
          if (this.requiredFields.includes('time_off_policy') && this.sortedTimeOffPolicies.length === 1) {
            form.timeOffPolicy = this.sortedTimeOffPolicies[0].id
          }

          this.formDataChanged(form)
        } else {
          // First patch up fields coming from server.
          // There are legacy phone values set to empty object {} that will
          // cause form to be dirty even though it shouldn't be.
          if (_.isEmpty(newValue.phones)) newValue.phones = []
          if (_.isEmpty(newValue.emgPhones)) newValue.emgPhones = []
          // fix announcementOverride null
          newValue.announcementOverride = !!newValue.announcementOverride
          newValue.requireJobAccess = !!newValue.requireJobAccess
          if (!newValue.roles) newValue.roles = ['worker']
          if (!newValue.permissions) newValue.permissions = []
          if (!newValue.notifications) newValue.notifications = []
          if (!newValue.payRates) newValue.payRates = []
          newValue.banned = !!newValue.banned
          if (!newValue.bankInfo) newValue.bankInfo = {
            name: null,
            accountNo: null
          }

          this.enablePersonalDevice = !!newValue.personalDeviceMode

          form = _.cloneDeep(newValue)
          this.formDataChanged(form)
        }

        this.workerAllowOrgUnits = (form.workerAllowLabels || [])
          .map(label => parseInt(label.split(':')[2]))
      },
      immediate: true
    },
    formData (newValue) {
      this.form = newValue

      // This is a crazy hack to prevent E164PhoneNumberInput from clearing the mobilePhone value
      // when this form is hosted in a modal. It seems like some racing condition.
      if (newValue.mobilePhone) {
        const mobilePhone = newValue.mobilePhone
        setTimeout(() => {
          const input = _.get(this.$refs, 'e164.$refs.widget.$refs.PhoneNumberInput')
          if (input) input.emitValue(mobilePhone)
        }, 50)
      }
    },
    readOnly (value) {
      this.readOnlyChanged(value)
    },
    formInvalid: {
      handler (value) {
        this.formInvalidChanged(value)
      },
      immediate: true
    },
    payrollEnabled: {
      handler (value) {
        if (value) this.$store.dispatch('payClasses/load')
      },
      immediate: true
    },
    singleOrgUnit (value) {
      if (this.isWorker && value && !this.form.orgUnit) {
        this.form.orgUnit = this.sortedOrgUnits[0].id
      }
    },
    form (value) {
      if ((this.isWorker || this.enablePersonalDevice) && !value.orgUnit && this.singleOrgUnit) {
        this.form.orgUnit = this.sortedOrgUnits[0].id
      }
    },
    isWorker (newValue, oldValue) {
      if (newValue && !oldValue) {
        this.form.pin = null
        this.form.department = null
        this.form.payrollId = null
        this.form.payClass = null
        this.pullCustomFieldValues()
      }
      if (!newValue && oldValue) {
        if (this.form.personalDeviceMode) {
          // If personal device mode was worker-specific, then set to group mode.
          if (!this.personalDeviceModeOptions.map(o => o.value).includes(this.form.personalDeviceMode)) {
            this.form.personalDeviceMode = 'clockGroup'
          }
        }
        this.workerAllowOrgUnits = []
        this.pullCustomFieldValues()
      }
    },
    isAdmin (newValue, oldValue) {
      if (!newValue) {
        if (oldValue) {
          if (this.isOwner) {
            // remove owner role
            this.form.roles.splice(this.form.roles.indexOf('owner'), 1)
          }
          // If personal device mode was admin-specific, then set to group mode.
          if (this.form.personalDeviceMode === 'admin') {
            this.form.personalDeviceMode = 'clockGroup'
          }
        }
        // remove all permissions
        this.form.permissions = []
        this.form.adminAllowLabels = []
        this.form.notifications = []
        this.form.requireJobAccess = false
      }
    },
    isOwner (value) {
      if (value) {
        if (!this.isAdmin) {
          this.form.roles.push('admin')
        }
        // owner must have access to all allow labels
        this.form.adminAllowLabels = []
        this.workerAllowOrgUnits = []
        this.form.requireJobAccess = false
        // Avoid accidentally removing subscription_alerts notification when user edits roles without saving.
        if (!this.form.notifications.includes('subscription_alerts') && this.originalData.notifications && this.originalData.notifications.includes('subscription_alerts')) {
          this.form.notifications.push('subscription_alerts')
        }
        // TODO: Restore subscription_alerts to originalData.notifications.
        // owner must have all permissions
        this.$nextTick(() => { // next tick in case permissions not rendered yet
          this.$refs.permissions.$.vnode.children.default().forEach(child => {
            if (child.type.__name === 'BFormCheckbox') {
              if (!this.form.permissions.includes(child.props.value)) {
                this.form.permissions.push(child.props.value)
              }
            }
          })
        })
      } else {
        // only owner allowed to have permission to manage billing
        this.form.permissions = this.form.permissions.filter(perm => perm !== 'manage_subscription')
        this.form.notifications = this.form.notifications.filter(item => item !== 'subscription_alerts')
      }
    },
    'form.active' (active) {
      if (!active) this.form.notifications = []
    },
    'form.permissions' (newValue, oldValue) {
      if (newValue) {
        if (!newValue.includes('edit_employees')) {
          if (newValue.includes('access_employee_sensitive')) {
            this.form.permissions = newValue.filter(perm => perm !== 'access_employee_sensitive')
          }
          if (newValue.includes('edit_org_users')) {
            this.form.permissions = newValue.filter(perm => perm !== 'edit_org_users')
          }
        }
        if (newValue.includes('edit_punches')) {
          if (!newValue.includes('edit_punch_attributes')) {
            this.form.permissions = newValue.concat(['edit_punch_attributes'])
          }
        }
      }
    },
    enablePersonalDevice (enablePersonalDevice) {
      if (enablePersonalDevice) {
        if (this.form.roles.includes('admin')) {
          this.form.reinstallPerm = 'self'
        }
      } else {
        this.form.personalDeviceMode = null
        if (!this.isWorker) {
          this.form.orgUnit = null
        }
      }
    },
    personalDeviceAllowed (personalDeviceAllowed) {
      if (personalDeviceAllowed) {
        if (!this.form.orgUnit && this.singleOrgUnit) {
          this.form.orgUnit = this.sortedOrgUnits[0].id
        }
      } else {
        this.enablePersonalDevice = false
        if (!this.isWorker) {
          this.form.orgUnit = null
        }
      }
    },
    'form.qrCode' (qrCode) {
      // I don't know why, but some users have gotten an empty string qr code,
      // which causes unique validation failure. It's occurred in both Safari and Chrome,
      // and even with qr code mode disabled.
      // So we'll just patch it, without really knowing why it occurs.
      if (qrCode === '') this.form.qrCode = null
    },
    'form.customFieldValues' () {
      this.pullCustomFieldValues()
    },
    customFieldValues: {
      handler () {
        this.pushCustomFieldValues()
      },
      deep: true
    },
    allCustomFields () {
      this.pullCustomFieldValues()
    },
    workerAllowOrgUnits (workerAllowOrgUnits) {
      this.form.workerAllowLabels = (workerAllowOrgUnits || []).map(id => `device:orgunit:${id}`)
    }
  },
  computed: {
    ...mapGetters([
      'accessAllOrgUnits',
      'canViewAudit',
      'hasDocumentFeature',
      'hasFaceFeature',
      'enableBriefings',
      'geoEnabled',
      'credentialsEnabled',
      'costingEnabled',
      'healthAndSafetyEnabled',
      'netPayEnabled',
      'payrollEnabled',
      'payRunEnabled',
      'timeOffEnabled',
      'timeOffAccrualEnabled',
      'scheduleEnabled',
      'canAccessEmployeeSensitive',
      'canEditAdministrators',
      'canEditSelf',
      'canManagePayroll',
      'isAccountOwner',
      'canAccessEmployeeSensitiveField',
      'canViewWorkerTimeOffPolicy',
      'canRestrictJobAccessByUser',
      'hasCustomFieldFeature',
      'disciplineEnabled',
      'hasNotificationFeature'
    ]),
    ...mapGetters({
      credentialPolicies: 'credentialPolicies/sortedItems',
      credentialTypes: 'credentialTypes/sortedItems',
      departments: 'departments/sortedItems',
      orgUnits: 'orgUnits/sortedItems',
      payClasses: 'payClasses/sortedItems',
      teamSchedules: 'teamSchedules/sortedItems',
      timeOffPolicies: 'timeOffPolicies/sortedItems',
      formatDateTime: 'formatPreferences/formatDateTime',
      formatName: 'formatPreferences/formatName',
      formatSecondsAsDuration: 'formatPreferences/formatSecondsAsDuration',
      isWhiteLabelUser: 'userProfile/isWhiteLabel',
      customFieldById: 'customFields/itemsById',
      userCustomFields: 'customFields/userCustomFields',
      workCustomFields: 'customFields/workCustomFields',
      orgUnitById: 'orgUnits/itemsById',
    }),
    ...mapState([
      'nfcTagEnabled',
      'orgUserId',
      'qrCodeEnabled',
      'adminAllowLabels',
      'adminPersonalDeviceModeAllowed'
    ]),
    ...mapState('employeeDefaults', {
      // employeeDefaults could be could empty if load failed
      // TODO: What if the user is not a worker?
      requiredFields: state => state.originalData.requiredFields || [],
      nationalIdUnique: state => state.originalData.nationalIdUnique
    }),
    ...mapGetters('userProfile', ['isSudoUser']),
    ...mapGetters('employeeDefaults', [
      'authDomain',
      'canCreateEmployeeSensitiveField',
      'employeeSensitiveCanCreateFields',
      'employeeSensitiveCanDelete',
      'employeeSensitivePayClassReadOnly',
      'pinLengthMin',
      'pinLengthMax'
    ]),
    notificationOptions () {
      const options = [
        { value: 'subscription_alerts', label: 'Billing events', helpText: 'If enabled, this user will receive email notifications on all subscription events, including billing and renewals. User must have also permission to Manage Billing in order to receive these notifications.', disabled: !this.isOwner },
        { value: 'clock_alerts', label: 'Device alerts', helpText: 'If enabled, this user will receive device-related notifications, such as device offline or incorrect system time.'},
        { value: 'discipline_alerts', label: 'Attendance alerts', helpText: 'If enabled, this user will receive email notifications if an Attendance Event is generated, and a push notification is an Attendance Infraction is generated.'},
        { value: 'geo_alerts', label: 'Geo location alerts', helpText: 'If enabled, this user will receive notifications for geo location alerts, such as a worker leaving a geo fence and connection timeout.', available: this.geoEnabled && this.hasNotificationFeature },
        { value: 'user_credential', label: 'Credential alerts', helpText: 'This notification is sent if a worker updates a credential, or attempts to punch with an invalid credential', available: this.credentialsEnabled && this.hasNotificationFeature },
        { value: 'custom_field_triggers', label: 'Custom field triggers', helpText: 'This notification is sent if a worker submits a custom field that triggers a condition to notify administrators.', available: this.hasCustomFieldFeature && this.hasNotificationFeature },
        { value: 'missed_punch_requests', label: 'Missed punch requests', available: this.hasNotificationFeature },
        { value: 'shift_reminders', label: 'Shift reminders', helpText: 'Shift reminders may be set up under an Attendance Policy with an option for each reminder to alert administrators in addition to the worker.', available: this.disciplineEnabled && this.hasNotificationFeature },
        { value: 'time_off_requests', label: 'Time off requests', available: this.timeOffEnabled && this.hasNotificationFeature },
      ].filter(option => option.available !== false)
      return _.orderBy(options, 'label')
    },
    adminAllowUserOrgUnits () {
      return (this.adminAllowLabels || [])
        .filter(label => label.startsWith('user:orgunit:'))
        .map(label => parseInt(label.split(':')[2]))
    },
    isSelf () {
      return this.originalData.id === this.orgUserId
    },
    canEditPayClass () {
      return this.canAccessEmployeeSensitive ||
        (this.shouldShowEmployeeSensitiveField('pay_class') &&
        !this.employeeSensitivePayClassReadOnly)
    },
    canDeleteEmployee () {
      return this.canAccessEmployeeSensitive || this.employeeSensitiveCanDelete
    },
    canAccessCredentials () {
      return this.canAccessEmployeeSensitiveField('credential') &&
        this.canAccessEmployeeSensitiveField('document')
    },
    canAccessDocuments () {
      return this.hasDocumentFeature && this.canAccessEmployeeSensitiveField('document')
    },
    healthAndSafetyVisible () {
      return this.healthAndSafetyEnabled && this.isWorker
    },
    documentsVisible () {
      return this.hasDocumentFeature && !this.addingNew && this.isWorker
    },
    faceModelVisible () {
      return this.hasFaceFeature && !this.addingNew && this.form.active && this.isWorker
    },
    sortedCredentialPolicies () {
      return this.credentialPolicies('name').filter(item => item.active || item.id === this.originalData?.credentialPolicy)
    },
    sortedCredentialTypes () {
      return this.credentialTypes('name')
    },
    sortedDepartments () {
      return this.departments('name').filter(item => item.active)
    },
    sortedDisciplinePolicies () {
      return this.$store.getters['disciplinePolicies/sortedItems']('name').filter(item => item.active || item.id === this.originalData.disciplinePolicy)
    },
    sortedOrgUnits () {
      return this.orgUnits('name').filter(item => item.active)
    },
    sortedPayClasses () {
      return this.payClasses('name')
    },
    sortedTeamSchedules () {
      return this.teamSchedules('name').filter(item => item.active || item.id === this.form.teamSchedule)
    },
    sortedTimeOffPolicies () {
      return this.timeOffPolicies('name').filter(item => item.active || item.id === this.form.timeOffPolicy)
    },
    orgUnitName () {
      return this.orgUnitById?.[this.form.orgUnit]?.name ?? null
    },
    formInvalid () {
      return this.v$.$invalid
    },
    invalidFields () {
      return Object.keys(this.v$.form.$params)
        .filter(fieldName => this.v$.form[fieldName].$invalid)
    },
    readOnly () {
      return this.isSelf && !this.canEditSelf
    },
    singleOrgUnit () {
      return this.accessAllOrgUnits && this.sortedOrgUnits.length === 1
    },
    isWorker () {
      return !!this.form.roles && this.form.roles.includes('worker')
    },
    isAdmin () {
      return !!this.form.roles && this.form.roles.includes('admin')
    },
    workerPersonalDeviceEnabled () {
      return this.isWorker && !!this.form.personalDeviceMode
    },
    wasAdmin () {
      return !!this.originalData.roles && this.originalData.roles.includes('admin')
    },
    isOwner () {
      return !!this.form.roles && this.form.roles.includes('owner')
    },
    showNotifications () {
      return this.form.active && (this.isAdmin || (this.isWorker && !!this.form.personalDeviceMode))
    },
    showEmailSuppression () {
      return this.isAdmin || (this.isWorker && !!this.originalData.personalDeviceMode && !!this.originalData.email)
    },
    showSmsSuppression () {
      return this.isWorker && !!this.originalData.personalDeviceMode && !!this.originalData.mobilePhone
    },
    isAllEmailSuppressed () {
      return this.showEmailSuppression && !!_.get(this.originalData.contactSuppressions, 'email.isSuppressed')
    },
    isAllSmsSuppressed () {
      return this.showSmsSuppression && !!_.get(this.originalData.contactSuppressions, 'sms.isSuppressed')
    },
    isBillingEmailUnsubscribed () {
      return this.isGroupEmailUnsubscribed(BILLING_EMAIL_GROUP)
    },
    isDeviceAlertEmailUnsubscribed () {
      return this.isGroupEmailUnsubscribed(DEVICE_ALERT_EMAIL_GROUP)
    },
    isDisciplineAlertEmailUnsubscribed () {
      return this.isGroupEmailUnsubscribed(DISCIPLINE_ALERT_EMAIL_GROUP)
    },
    isScheduledReportEmailUnsubscribed () {
      return this.isGroupEmailUnsubscribed(REPORT_EMAIL_GROUP)
    },
    phone1: {
      get () {
        return this.form.phones ? this.form.phones[0] : null
      },
      set (v) {
        if (!this.form.phones) this.form.phones = []
        this.form.phones[0] = v
      }
    },
    phone2: {
      get () {
        return this.form.phones ? this.form.phones[1] : null
      },
      set (v) {
        if (!this.form.phones) this.form.phones = []
        this.form.phones[1] = v
      }
    },
    emgPhone1: {
      get () {
        return this.form.emgPhones ? this.form.emgPhones[0] : null
      },
      set (v) {
        if (!this.form.emgPhones) this.form.emgPhones = []
        this.form.emgPhones[0] = v
      }
    },
    emgPhone2: {
      get () {
        return this.form.emgPhones ? this.form.emgPhones[1] : null
      },
      set (v) {
        if (!this.form.emgPhones) this.form.emgPhones = []
        this.form.emgPhones[1] = v
      }
    },
    lastPunch () {
      return this.originalData.lastPunch ? this.formatDateTime(this.originalData.lastPunch) : null
    },
    created () {
      return this.originalData.created ? this.formatDateTime(this.originalData.created) : null
    },
    modified () {
      return this.originalData.modified ? this.formatDateTime(this.originalData.modified) : null
    },
    lastAccess () {
      return this.originalData.lastAccess ? this.formatDateTime(this.originalData.lastAccess) : null
    },
    defaultDisplayName () {
      if (!this.form.firstName || !this.form.lastName) return ''
      return this.formatName(this.form.firstName, this.form.lastName, this.form.middleName, this.form.displayName)
    },
    displayNameOptions () {
      if (!this.form.firstName || !this.form.lastName) return []

      const options = [
        `${this.form.firstName} ${this.form.lastName}`,
        `${this.form.lastName}, ${this.form.firstName}`,
      ]

      if (this.form.displayName && !options.includes(this.form.displayName)) {
        options.push(this.form.displayName)
      }

      return options.map(option => ({ value: option, label: option }))
    },
    personalDeviceModeOptions () {
      return Object.freeze(
        Object.entries(personalDeviceModeOptions)
        .filter(entry => entry[1].available?.(this.formData) !== false)
          .map(entry => Object.assign({ value: entry[0] }, entry[1]))
      )
    },
    personalDeviceAllowed () {
      return this.form.active && (this.form.roles?.includes('worker') || this.adminPersonalDeviceModeAllowed)
    },
    originalCustomFieldValueById () {
      return Object.fromEntries((this.originalData.customFieldValues || []).map(v => [v.field, v]))
    },
    allCustomFields () {
      return this.userCustomFields.concat(
        this.workCustomFields.filter(customField => {
          const conditions = new Set(customField.conditions || [])
          if (
            conditions.size < 1 ||
            conditions.has('user:all') ||
            conditions.has(`user:worker:${this.form.id}`) ||
            conditions.has(`user:dept:${this.form.department}`) ||
            _.some(this.form.labels || [], label => conditions.has(`user:label:${label}`))
          ) return true
          return false
        })
      )
    },
    nextShiftReminderDisplay () {
      const data = this.form.nextShiftReminder
      return data ? `${_.startCase(data.reminderType)} reminder at ${this.$store.getters['formatPreferences/formatDateTimeTz'](data.dt, data.timezone)}` : 'none'
    },
    netPayAdjustmentLink () {
      return {
        name: 'net-pay-adjustment-list',
        params: {
          view: JSON.stringify({
            active: 'active',
            worker: this.form.id
          })
        }
      }
    },
    allowsHomeOrgUnit () {
      return _.isEmpty(this.workerAllowOrgUnits) || !this.form.orgUnit || this.workerAllowOrgUnits.includes(this.form.orgUnit)
    },
    nfcTagIdValid () {
      if (!this.form.nfcTagId) return true
      const nfcTagId = this.canonicalNfcTagId(this.form.nfcTagId)
      return (
        /^[0-9A-F]{8,16}$/.test(nfcTagId) &&
        nfcTagId.length % 2 === 0
      )
    }
  },
  validations () {
    const validations = {
      $validationGroups: {
        basicInformationGroup: ['form.roles', 'form.firstName', 'form.lastName'],
        extraInformationGroup: [],
        customFieldsGroup: [],
        permissionsGroup: ['form.permissions'],
        personalDeviceGroup: ['form.mobilePhone', 'form.personalDeviceMode', 'form.reinstallPerm'],
        compensationGroup: [],
        netPayGroup: [],
        disciplineGroup: [],
        identificationGroup: [],
        notificationsGroup: ['form.notifications'],
        workHistoryGroup: [],
      },
      form: {
        roles: {
          required: helpers.withMessage('User must have at least one role.', required),
          ownerAllowed: helpers.withMessage(
            'You are not allowed to edit Account Owners.',
            value => this.isAccountOwner || !value || !value.includes('owner')
          ),
          adminAllowed: helpers.withMessage(
            'You are not allowed to edit Administrators.',
            value => this.canEditAdministrators || !value || !value.includes('admin')
          ),
          ownerRequired: helpers.withMessage(
            'You are not allowed to remove Account Owner role from yourself.',
            value => {
              const removedSelfOwner = this.isSelf && !this.isOwner && this.originalData.roles.includes('owner')
              return !removedSelfOwner
            }
          ),
          adminRequired: helpers.withMessage(
            'You are not allowed to edit Administrator role from yourself.',
            value => {
              const removedSelfAdmin = this.isSelf && !this.isAdmin && this.originalData.roles.includes('admin')
              return !removedSelfAdmin
            }
          )
        },
        email: {
          email
        },
        firstName: {
          required,
          maxLength: maxLength(40)
        },
        middleName: {
          maxLength: maxLength(40)
        },
        lastName: {
          required,
          maxLength: maxLength(40)
        },
        displayName: {
          maxLength: maxLength(100)
        },
        orgUnit: {
          required: requiredIf(model => this.isWorker || this.enablePersonalDevice)
        },
        mobilePhone: {
          required: helpers.withMessage(
             'Either Mobile phone # or Email must be specified.',
            requiredIf(model => this.enablePersonalDevice && !this.form.email)
          ),
          phoneE164: (value) => !this.enablePersonalDevice || !value || this.mobilePhoneValid,
          isUnique: helpers.withMessage(
            ({ $response }) => {
              return $response?.message
            },
            debounceAsyncValidator(function (value, parentVm, debounce) {
              // synchronous validations
              if (!value) return true
              if (!this.enablePersonalDevice) return true
              if (value === this.originalData.mobilePhone) return true
              if (!this.mobilePhoneValid) return true
              // capture all component state synchronously
              const employeeId = this.originalData.id
              return debounce()
                .then(() => employeeService.lookupByPhone(value))
                .then(employee => {
                  const isUnique = !employee || employee.id === employeeId
                  return {
                    $valid: isUnique,
                    message: isUnique ? null : `"${this.formatName(employee.firstName, employee.lastName)}" already has this phone number.`
                  }
                })
                .catch(() => {
                  // could be caused by either rest api failure or by debounce
                  return {
                    $valid: false,
                    message: 'Unable to determine if phone number is unique.'
                  }
                })
            }, 500)
          )
        },
        personalDeviceMode: {
          required: requiredIf(model => this.enablePersonalDevice)
        },
        reinstallPerm: {
          required: requiredIf(model => this.enablePersonalDevice)
        },
        emgEmail: {
          email
        },
        permissions: {
          adminRequired: helpers.withMessage(
            '** An administrator must have at least one permission.',
            value => {
              if (!this.isAdmin) return true
              return !_.isEmpty(value)
            }
          )
        },
        notifications: {
          suppressed: helpers.withMessage(
            'The user is unsubscribed from one or more enabled notifications above.',
            value => {
              if (this.isAllEmailSuppressed && !_.isEmpty(value)) return false
              if (this.isBillingEmailUnsubscribed && value.includes('subscription_alerts')) return false
              if (this.isDeviceAlertEmailUnsubscribed && value.includes('clock_alerts')) return false
              if (this.isDisciplineAlertEmailUnsubscribed && value.includes('discipline_alerts')) return false
              return true
            }
          )
        }
      }
    }

    if (this.isWorker) {
      validations.$validationGroups.basicInformationGroup.push('form.orgUnit', 'form.pin', 'form.qrCode', 'form.nfcTagId')
      validations.$validationGroups.extraInformationGroup.push('form.department', 'form.labels', 'form.payClass', 'form.payrollId', 'form.teamSchedule', 'form.timeOffPolicy')
      validations.$validationGroups.netPayGroup.push('form.bankInfo.name', 'form.bankInfo.accountNo')
      validations.$validationGroups.compensationGroup.push('form.payRates')
      validations.$validationGroups.identificationGroup.push('form.nationalId')
      validations.$validationGroups.disciplineGroup.push('form.disciplinePolicy')
      validations.$validationGroups.permissionsGroup.push('form.workerAllowLabels')
      validations.$validationGroups.workHistoryGroup.push('form.hireDate')

      const pinLengthErrorMessage = this.pinLengthMin === this.pinLengthMax
        ? `Pin must be ${this.pinLengthMin} digits.`
        : `Pin must be between ${this.pinLengthMin} and ${this.pinLengthMax} digits.`

      Object.assign(validations.form, {
        pin: {
          required,
          integer,
          minLength: helpers.withMessage(
            pinLengthErrorMessage,
            minLength(this.pinLengthMin)
          ),
          maxLength: helpers.withMessage(
            pinLengthErrorMessage,
            maxLength(this.pinLengthMax)
          ),
          isUnique: helpers.withMessage(
            ({ $response }) => {
              return $response?.message
            },
            debounceAsyncValidator(function (value, parentVm, debounce) {
              // synchronous validations
              if (!value) return true
              if (value === this.originalData.pin) return true
              if (!new RegExp(`^[0-9]{${this.pinLengthMin},${this.pinLengthMax}}$`).test(value)) return true
              // capture all component state synchronously
              const employeeId = this.originalData.id
              return debounce()
                .then(() => employeeService.lookupByPin(value))
                .then(employee => {
                  const isUnique = !employee || employee.id === employeeId
                  return {
                    $valid: isUnique,
                    message: isUnique ? null : `"${this.formatName(employee.firstName, employee.lastName)}" already has this pin.`
                  }
                })
                .catch(() => {
                  // could be caused by either rest api failure or by debounce
                  return {
                    $valid: false,
                    message: 'Unable to determine if pin is unique.'
                  }
                })
            }, 500)
          )
        },
        qrCode: {
          isUnique: helpers.withMessage(
            ({ $response }) => {
              return $response?.message
            },
            debounceAsyncValidator(function (value, parentVm, debounce) {
              // synchronous validations
              if (!value) return true
              if (value === this.originalData.qrCode) return true
              // capture all component state synchronously
              const employeeId = this.originalData.id
              return debounce()
                .then(() => employeeService.lookupByQrCode(value))
                .then(employee => {
                  const isUnique = !employee || employee.id === employeeId
                  return {
                    $valid: isUnique,
                    message: isUnique ? null : `"${this.formatName(employee.firstName, employee.lastName)}" already has this QR code.`
                  }
                })
                .catch(() => {
                  // could be caused by either rest api failure or by debounce
                  return {
                    $valid: false,
                    message: 'Unable to determine if QR code is unique.'
                  }
                })
            }, 500)
          )
        },
        nfcTagId: {
          validFormat: helpers.withMessage(
            'NFC Tag ID is not in valid format',
            () => {
              if (!this.form.nfcTagId) return true
              return this.nfcTagIdValid
            }
          ),
          isUnique: helpers.withMessage(
            ({ $response }) => {
              return $response?.message
            },
            debounceAsyncValidator(function (value, parentVm, debounce) {
              // synchronous validations
              if (!value) return true
              if (!this.nfcTagIdValid) return true
              if (this.canonicalNfcTagId(value) === this.canonicalNfcTagId(this.originalData.nfcTagId)) return true
              // capture all component state synchronously
              const employeeId = this.originalData.id
              return debounce()
                .then(() => employeeService.lookupByNfcTagId(value))
                .then(employee => {
                  const isUnique = !employee || employee.id === employeeId
                  return {
                    $valid: isUnique,
                    message: isUnique ? null : `"${this.formatName(employee.firstName, employee.lastName)}" already has this NFC Tag ID.`
                  }
                })
                .catch(() => {
                  // could be caused by either rest api failure or by debounce
                  return {
                    $valid: false,
                    message: 'Unable to determine if NFC Tag ID is unique.'
                  }
                })
            }, 500)
          )
        },
        credentialPolicy: {
          required: requiredIf(model => this.requiredFields.includes('credential_policy') && this.credentialsEnabled)
        },
        department: {
          required: requiredIf(model => this.requiredFields.includes('department'))
        },
        disciplinePolicy: {
          required: requiredIf(model => this.requiredFields.includes('discipline_policy') && this.disciplineEnabled)
        },
        hireDate: {
          required: requiredIf(model => this.requiredFields.includes('hire_date'))
        },
        labels: {
          required: requiredIf(model => this.requiredFields.includes('labels'))
        },
        payClass: {
          required: requiredIf(model => this.requiredFields.includes('pay_class') && this.payrollEnabled)
        },
        teamSchedule: {
          required: requiredIf(model => this.requiredFields.includes('team_schedule') && this.scheduleEnabled)
        },
        timeOffPolicy: {
          required: requiredIf(model => this.requiredFields.includes('time_off_policy') && this.timeOffAccrualEnabled)
        },
        payrollId: {
          required: requiredIf(model => this.requiredFields.includes('payroll_id')),
          maxLength: maxLength(50)
        },
        payRates: {
          required: value => {
            if (!this.requiredFields.includes('pay_rates')) return true
            // TODO: Once we add pay class salary pay mode, we should check that
            // TODO: there's at least one pay rate for assigned pay class's pay mode.
            return value.length > 0
          }
        },
        nationalId: {
          required: requiredIf(model => this.requiredFields.includes('national_id')),
          maxLength: maxLength(30),
          isUnique: helpers.withMessage(
            ({ $response }) => {
              return $response?.message
            },
            debounceAsyncValidator(function (value, parentVm, debounce) {
              // synchronous validations
              if (!this.nationalIdUnique) return true
              if (!value) return true
              if (value === this.originalData.nationalId) return true
              if (value.length > 30) return true
              // capture all component state synchronously
              const employeeId = this.originalData.id
              // Uniqueness is determined by alphanumerics only, and in lowercase.
              // Although the backend converts the strings, since currently we put the
              // value on the url, it doesn't handle all possible characters.
              const nationalId = value.replace(/[^a-z0-9]/gi,'').toLowerCase()
              return debounce()
                .then(() => employeeService.lookupByNationalId(nationalId))
                .then(employee => {
                  const isUnique = !employee || employee.id === employeeId
                  return {
                    $valid: isUnique,
                    message: isUnique ? null : `"${this.formatName(employee.firstName, employee.lastName)}" already has this National ID / SSN.`
                  }
                })
                .catch(() => {
                  // could be caused by either rest api failure or by debounce
                  return {
                    $valid: false,
                    message: 'Unable to determine if National ID / SSN is unique.'
                  }
                })
            }, 500)
          )
        },
        workerAllowLabels: {
          allowed: helpers.withMessage(
            'You do not have permission to grant workers this level of org unit access',
            () => {
              if (_.isEmpty(this.adminAllowUserOrgUnits)) return true
              if (_.isEmpty(this.workerAllowOrgUnits)) return false
              return this.workerAllowOrgUnits.every(orgUnitId => this.adminAllowUserOrgUnits.includes(orgUnitId))
            }
          ),
          allowsHomeOrgUnit: helpers.withMessage(
            `You must also allow the home Org Unit "${this.orgUnitName}" that is set in the Basic Information section`,
            () => {
              // If worker with personal device, then must allow home org unit.
              return (
                !this.workerPersonalDeviceEnabled ||
                this.allowsHomeOrgUnit
              )
            }
          )
        },
        bankInfo: {
          name: {
            maxLength: maxLength(50)
          },
          accountNo: {
            maxLength: maxLength(50)
          }
        }
      })
    }

    if (this.isAdmin) {
      validations.$validationGroups.basicInformationGroup.push('form.email')
      validations.$validationGroups.permissionsGroup.push('form.adminAllowLabels')

      Object.assign(validations.form, {
        email: {
          required,
          email,
          matchesRequiredDomain: helpers.withMessage(
            'Email domain name does not match organization policy.',
            value => {
              if (!value) return true
              if (!isEmail(value)) return true
              if (!this.authDomain) return true
              return new RegExp(`(?:@${this.authDomain})$`, 'i').test(value)
            }
          )
        },
        adminAllowLabels: {
        }
      })

      if (!this.isWorker) {
        validations.$validationGroups.personalDeviceGroup.push('form.orgUnit')
      }
    }

    if (this.isAdmin || this.enablePersonalDevice) {
      if (!this.isAdmin) {
        validations.$validationGroups.personalDeviceGroup.push('form.email')
      }

      Object.assign(validations.form.email, {
        isUnique: helpers.withMessage(
          ({ $response }) => {
            return $response?.message
          },
          debounceAsyncValidator(function (value, parentVm, debounce) {
            // synchronous validations
            if (!value) return true
            if (value.toLowerCase() === this.originalData.email) {
              if (!(!this.wasAdmin && this.isAdmin) && !(!this.originalData.personalDeviceMode && this.enablePersonalDevice)) {
                return true
              }
            }
            if (!isEmail(value)) return true
            if (this.isAdmin && this.authDomain && !new RegExp(`(?:@${this.authDomain})$`, 'i').test(value)) return true
            // capture all component state synchronously
            const employeeId = this.originalData.id
            return debounce()
              .then(() => employeeService.lookupByEmail(nationalId))
              .then(employee => {
                const isUnique = !result || !result.exists || (result.id && result.id === employeeId)
                return {
                  $valid: isUnique,
                  message: isUnique
                    ? null
                    : (result.id
                      ? `"${this.formatName(result.firstName, result.lastName)}" already has this email.`
                      // in use by superuser
                      : 'This email is not available.')
                }
              })
              .catch(() => {
                // could be caused by either rest api failure or by debounce
                return {
                  $valid: false,
                  message: 'Unable to determine if email is unique.'
                }
              })
          }, 500)
        )
      })
    }

    if (this.isWorker && this.allCustomFields.length > 0) {
      for(const customField of this.allCustomFields) {
        validations.$validationGroups.customFieldsGroup.push('customFieldValues')
        validations.customFieldValues = {
          $each: helpers.forEach({
            value: {
              required: (value, parentVm) => {
                const customField = this.customFieldById[parentVm.field]
                if (customField.appliesTo !== 'user' || !customField.required) return true
                // Vuelidate's required function doesn't check booleans, so we need to do it ourselves.
                return hasValue(value, true)
              },
              numeric: (value, parentVm) => {
                if (!value) return true
                const customField = this.customFieldById[parentVm.field]
                if (customField.type !== 'number') return true
                const index = this.customFieldValues.findIndex(v => v.field == customField.id)
                return !isNaN(value)
              },
              maxLength: (value, parentVm) => {
                if (!value) return true
                const customField = this.customFieldById[parentVm.field]
                if (customField.type !== 'string') return true
                return maxLength(customField.stringMultiline ? 200 : 100)(value)
              },
              isUnique: helpers.withMessage(
                ({ $response }) => {
                  return $response?.message
                },
                debounceAsyncValidator(function (value, parentVm, debounce) {
                  // synchronous validations
                  if (!value) return true
                  const customField = this.customFieldById[parentVm.field]
                  if (!customField.unique) return true
                  if (value === _.get(this.originalCustomFieldValueById[customField.id], 'value')) return true
                  const index = this.customFieldValues.findIndex(v => v.field == customField.id)
                  if (customField.type === 'number' && isNaN(value)) return true
                  if (customField.type === 'string' && value.length > (customField.stringMultiline ? 200 : 100)) return true
                  // Capture all component state synchronously.
                  const orgUserId = this.originalData.id
                  return debounce()
                    .then(() => employeeService.lookupByCustomField(customField.id, value))
                    .then(employee => {
                      const isUnique = !orgUser || orgUser.id === orgUserId
                      return {
                        $valid: isUnique,
                        message: isUnique ? null : `"${this.formatName(employee.firstName, employee.lastName)}" already has this ${customField.name}.`
                      }
                    })
                    .catch(() => {
                      // could be caused by either rest api failure or by debounce
                      return {
                        $valid: false,
                        message: `Unable to determine if ${customField.name} is unique.`
                      }
                    })
                }, 500)
              )
            }
          })
        }
      }
    }

    return validations
  },
  created () {
    this.$store.dispatch('employeeDefaults/load')
    this.$store.dispatch('departments/load')
    this.$store.dispatch('orgUnits/load')
    if (this.scheduleEnabled) {
      this.$store.dispatch('teamSchedules/load')
    }
    if (this.canViewWorkerTimeOffPolicy) {
      this.$store.dispatch('timeOffPolicies/load')
    }
    if (this.healthAndSafetyEnabled && this.canAccessCredentials) {
      this.$store.dispatch('credentialPolicies/load')
      if (this.credentialsEnabled) {
        this.$store.dispatch('credentialTypes/load')
      }
    }
    if (this.hasCustomFieldFeature) {
      this.$store.dispatch('customFields/load')
    }
    if (this.disciplineEnabled) {
      this.$store.dispatch('disciplinePolicies/load')
    }
  },
  methods: {
    ...mapMutations('userMasterDetail', ['readOnlyChanged']),
    deleteItem () {
      this.showModal({
        component: DeleteUserModal,
        props: {
          onDelete: () => {
            // Not so happy with current way we delete:
            // MasterDetail listens for 'deleteItem' event on bus,
            // and processes request.
            this.eventBus.emit('deleteItem', this.form.id)
          }
        }
      })
    },
    resendInvite () {
      this.resendingInvite = true
      employeeService.resendInvite(this.originalData.id)
        .then(() => this.$toast.success('Resent invitation'))
        .catch(() => this.$toast.error('Failed to resend invitation'))
        .finally(() => { this.resendingInvite = false })
    },
    shouldShowEmployeeSensitiveField (field) {
      return this.addingNew
        ? this.canCreateEmployeeSensitiveField(field)
        : this.canAccessEmployeeSensitiveField(field)
    },
    randomPin () {
      const pin = randomDigits(this.pinLengthMax)
      this.form.pin = pin
    },
    randomQrCode () {
      const qrCode = randomText(20) // pick a length that should be unguessable
      this.form.qrCode = qrCode
    },
    printQrCode () {
      toQrCodeDataURL(this.form.qrCode)
        .then(url => {
          var image = new Image()
          image.src = url
          const w = window.open('', '_blank')
          if (!w || !w.document) {
            return Promise.reject(new Error('A pop-up blocker is not allowing the QR code to be printed.'))
          }
          w.document.open()
          w.document.write(image.outerHTML)
          w.document.close()
          w.focus()
          setTimeout(
            () => printWindow(w).then(() => w.close()),
            100
          )
        })
        // TODO: handle error
    },
    avatarChanged (event) {
      // update avatar as long as same employee still in form
      if (this.originalData.id !== event.employeeId) return

      this.originalData.avatar = event.avatar
      this.form.avatar = event.avatar
    },
    resendPersonalDeviceLink () {
      this.personalDeviceSendLinkModalVisible = true
    },
    reinstallPersonalDevice () {
      this.creatingInstallCode = true
      employeeService.createInstallCode(this.originalData.id)
        .then(installCode => {
          this.originalData.installCode = installCode
          this.form.installCode = installCode
          this.personalDeviceSendLinkModalVisible = true
        })
        .catch(error => this.$toast.error(`Failed to create install code: ${extractErrorMessage(error)}`))
        .finally(() => { this.creatingInstallCode = false })
    },
    onCredentialingValidityChanged (isValid) {
      this.originalData.invalidCredentialing = this.form.invalidCredentialing = !isValid
    },
    onHasDocumentsChanged (hasDocuments) {
      this.originalData.hasDocuments = this.form.hasDocuments = hasDocuments
    },
    onHasCredentialsChanged (hasCredentials) {
      this.originalData.hasCredentials = this.form.hasCredentials = hasCredentials
    },
    toggleBan () {
      if (this.originalData.banned) this.unbanUser()
      else this.banUser()
    },
    async banUser () {
      const result = await this.confirmModal({
        props: {
          title: 'Ban User',
          body: `
            A banned user will be automatically deactivated and unable to clock IN to or OUT of work.
            Are you sure you want to ban this user?
          `,
          centered: true,
          noCloseOnBackdrop: true,
          okTitle: 'Yes, ban this user',
          okVariant: 'danger',
          cancelTitle: 'No, do not ban this user',
          cancelVariant: 'secondary'
        }
      })

      if (result) {
        this.eventBus.emit('updateItem', { ...this.originalData, banned: true, active: false })
      }
    },
    async unbanUser () {
      const result = await this.confirmModal({
        props: {
          title: 'Unlock Ban on User',
          body: `
            Are you sure you want to unlock the ban and reactivate this user?
          `,
          centered: true,
          noCloseOnBackdrop: true,
          okTitle: 'Yes, unlock the ban and reactivate this user',
          okVariant: 'success',
          cancelTitle: 'No, do not unlock the ban',
          cancelVariant: 'secondary'
        }
      })

      if (result) {
        this.eventBus.emit('updateItem', { ...this.originalData, banned: false, active: true })
      }
    },
    verifyAddress () {
      this.verifyingAddress = true
      import(/* webpackChunkName: "google-maps" */ '@/views/settings/devices/google-maps')
        .then(module => module.loadGoogleMapsApi(this.$.appContext.app))
        .then(() => {
          const geocoder = new google.maps.Geocoder()
          geocoder.geocode(
            { address: this.form.address },
            (results, status) => {
              if (status === google.maps.GeocoderStatus.OK) {
                console.log('Geo address select found results', results)
                // Choose first result.
                this.form.addressGeoCode = this.deserializeGeoCodeResult(results[0])
              } else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
                this.addressVerifyError = 'Geo address select found zero results'
                console.log('Geo address select found zero results')
              } else {
                this.addressVerifyError = `Geocode was not successful for the following reason: ${status}`
                console.log(this.addressVerifyError)
              }
              this.verifyingAddress = false
            }
          )
        })
        .catch(error => {
          console.warn('Error loading google maps module', error)
          this.addressVerifyError = 'Could not load google maps feature'
          this.verifyingAddress = false
        })
    },
    deserializeGeoCodeResult (geoCodeResult) {
      // https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingResponses

      function findAddressComponent (name, attribute, googleResult) {
        const component = _.find(
          googleResult.address_components,
          c => _.includes(c.types, name))
        return component ? component[attribute] : null
      }

      return {
        lat: geoCodeResult.geometry.location.lat(),
        lng: geoCodeResult.geometry.location.lng(),
        countryCode: findAddressComponent('country', 'short_name', geoCodeResult),
        postalCode: findAddressComponent('postal_code', 'short_name', geoCodeResult),
        administrativeArea: findAddressComponent('administrative_area_level_1', 'short_name', geoCodeResult),
        subAdministrativeArea: findAddressComponent('administrative_area_level_2', 'short_name', geoCodeResult),
        locality: findAddressComponent('locality', 'short_name', geoCodeResult),
        subLocality: findAddressComponent('sublocality', 'short_name', geoCodeResult),
        thoroughfare: findAddressComponent('route', 'short_name', geoCodeResult),
        subThoroughfare: findAddressComponent('street_number', 'short_name', geoCodeResult),
        formattedAddress: geoCodeResult.formatted_address
      }
    },
    addressChanged () {
      this.form.addressGeoCode = null
      this.addressVerifyError = null
    },
    downloadDataForm () {
      import(/* webpackChunkName: "download-data-form" */ './downloadDataForm')
        .then(module => module.downloadDataForm(this.originalData, this.userCredentials, this.supplementalData, this.$store))
        .catch(error => {
          console.warn('Error downloading data form', error)
          this.$toast.error('Error downloading data form')
        })
    },
    pullCustomFieldValues () {
      // Copy custom field values from form to component state.
      if (this.$store.state.customFields.loading || this.allCustomFields.length < 1) return
      this.customFieldValues = this.generateCustomFieldValues(this.form.customFieldValues)
    },
    pushCustomFieldValues () {
      // Copy custom field values from component state to form.
      if (this.$store.state.customFields.loading || this.allCustomFields.length < 1) return
      // Order by field id in order to match backend behavior and thus avoid false dirty detection.
      const customFieldValues = this.generateCustomFieldValues(this.customFieldValues, ['id'])
        .filter(item => hasValue(item.value))
      if (!_.isEqual(customFieldValues, this.form.customFieldValues)) {
        this.form.customFieldValues = customFieldValues
      }
    },
    generateCustomFieldValues (fieldValues, orderBy=[f => f.appliesTo !== 'user', 'appliesTo', 'sortOrder', 'name']) {
      if (!this.form.roles?.includes?.('worker')) return []
      const valuesByField = Object.fromEntries((fieldValues || []).map(value => [value.field, value]))
      return _.orderBy(this.allCustomFields, orderBy).map(field => valuesByField[field.id] || {
        field: field.id,
        value: field.default.value,
        type: field.type,
        index: field.active && field.appliesTo === 'user' && (['bool', 'choice'].includes(field.type) || field.unique),
        crossIndex: field.active && field.appliesTo === 'user' && field.crossIndex
      })
    },
    isGroupEmailUnsubscribed (groupId) {
      return this.isAdmin && (_.get(this.originalData.contactSuppressions, 'email.unsubscribeGroups') || []).includes(groupId)
    },
    getSuppressionReason (contactType) {
      const contact = _.get(this.originalData.contactSuppressions, contactType)
      if (!contact) return null
      const uriType = contactType == 'email' ? 'email address' : 'mobile phone number'
      switch (contact.suppressionType) {
        case 'block':
          return `Prior messages sent to this user's ${uriType} were blocked due to the following error: ${contact.suppressionReason}`
        case 'bounce':
          return `Prior messages sent to this user's ${uriType} bounced due to the following error: ${contact.suppressionReason}`
        case 'invalid':
          return 'The user\'s email address is invalid'
        case 'spam':
          return 'The user has reported Fareclock emails as spam'
        case 'unsubscribe':
        default:
          return `The user has unsubscribed from all Fareclock ${contactType === 'email' ? 'emails' : 'mobile texts'}`
      }
    },
    openMessageContactModal (contactType) {
      this.showModal({
        component: MessageContactModal,
        props: {
          contact: {
            // Remove uri/type once we save original suppression object with those properties.
            uri: contactType === 'email' ? this.originalData.email : this.originalData.mobilePhone,
            type: contactType,
            ...this.originalData.contactSuppressions[contactType]
          },
          name: this.defaultDisplayName,
          onSaved: contact => {
            this.originalData.contactSuppressions[contactType] = contact
            this.form.contactSuppressions[contactType] = contact
            // TODO: Update notifications is suppressed.
          }
        }
      })
    },
    canonicalNfcTagId (value) {
      return value && value.replaceAll(':', '').replaceAll('-', '').toUpperCase()
    },
    sendNotificationMessage () {
      this.showModal({
        component: SendNotificationModal,
        props: {
          orgUserId: this.originalData.id,
          orgUserName: this.defaultDisplayName
        }
      })
    }
  }
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/app/app';
@import '@/assets/scss/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.employee-form {
  input[type=email] {
    // Display email in canonical form.
    // Backend will do actual transform, so that cursor doesn't get messed up when editing.
    text-transform: lowercase;
  }
}

.resend-invite {
  margin-left: 10px;
}

.form-check-inline {
  // match bottom margin of form group
  margin-bottom: 1rem;
}

.custom-field-default-values-header {
  margin: 1rem;
  span {
    font-weight: bold;
    text-decoration: underline;
    text-transform: uppercase;
  }
  .help-text-icon {
    margin-left: .25rem;
  }
}

.pin-container, .qr-code-container {
  // do some flex magic to deal with bootstrap requirement
  // that invalid-feedback element is at same dom level as input.
  display: flex;
  flex-wrap: wrap;

  .form-control {
    flex-grow: 1;
  }

  .btn {
    margin-left: 10px;
  }

  .invalid-feedback {
    flex-basis: 100%;
  }
}

.pin-container .form-control {
  // setting width still allows it to expand, but it also
  // allows button to be on same line.
  width: 100px;
}

.qr-code-container .form-control {
  // setting width still allows it to expand, but it also
  // allows button to be on same line.
  width: 75px;
}

.line-break {
  flex-basis: 100%;
}

.address-mapping {
  // Undo address field's large bottom margin.
  margin: -.75rem .25rem .75rem .25rem;
  display: flex;
  justify-content: space-between;
  svg {
    margin-right: .25rem;
  }
  .verified-address {
    font-style: italic;
    color: $flat-ui-asbestos;
    display: flex;
    svg {
      margin-top: 3px;
    }
  }
  .verifying {
    color: $flat-ui-peter-river;
  }
  .verified {
    color: $flat-ui-emerald;
  }
  .not-verified {
    color: $flat-ui-alizarin;
  }
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;

  .form-check {
    flex-basis: 30%;

    @include media-breakpoint-down(md) {
      flex-basis: 45%;
    }

    @include media-breakpoint-only(xs) {
      flex-basis: 100%;
    }
  }
}

.checkbox-group-permissions {
  margin-top: 15px;
}

.device-uri-or {
    text-align: center;

  @include media-breakpoint-up(sm) {
    margin-top: 2rem;
  }
}

.credential-status {
  margin-top: 5px;
  font-size: 1.1rem;
  svg {
    margin-right: 7px;
  }
  &.success {
    color: $flat-ui-emerald;
  }
  &.error {
    color: $flat-ui-alizarin;
  }
}

.reset-auth {
  margin-left: 25px;
}

.download-data-form-disabled-message {
  margin-top: .25rem;
  font-style: italic;
  font-size: .8rem;
  color: $flat-ui-carrot;
  svg {
    margin-right: .25rem;
  }
}

.banned {
  color: $flat-ui-alizarin;
  font-weight: bold;
  svg {
    margin-right: 5px;
  }
}

.suppression-warning {
  color: $flat-ui-alizarin;
  svg {
    margin-right: 5px;
  }
}

.permissions {
  .title {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 1rem;
    text-decoration: underline;
  }
}

</style>
